import * as Yup from "yup";
import {
  Controller,
  useFieldArray,
  useForm,
} from "@pankod/refine-react-hook-form";
import {
  Box,
  Edit,
  EditButton,
  MenuItem,
  Select,
  ShowButton,
  TextField,
  Typography,
} from "@pankod/refine-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import { HttpError, useCustom, useList, useShow } from "@pankod/refine-core";
import {
  InvoiceAddressDataType,
  ProfileQueryDataType,
  UserQueryDataType,
} from "./show";
import { useParams } from "@pankod/refine-react-router-v6";
import FormEditAddress from "./FormEditAddress";
import { useMemo } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import FormItem from "components/FormItem";
import FormEditAddressList from "./FormEditAddressList";
import { REST_PUBLIC_URI } from "environment";

export interface ProfileEditDataType {
  first_name: string;
  sur_name: string;
  prefix: string;
  gender: string;
  phone_no: string;
  email: string;
  address: InvoiceAddressEditDataType[];
  occupation: string;
  company_name: string;
  user_id: string;
  profile_id: string;
  invoice_same_as_profile: boolean;
  primary_invoice: number | null;
  birth_date: string;
  personal_id: string;
  custom_occupation: string;
}

export interface InvoiceAddressEditDataType {
  invoice_address_id: number | null;
  address: string;
  name: string;
  invoice_number: string;
  phone_no: string;
  vat_license_image_url: string | null;
  type: "PERSONAL" | "COMPANY";
  user_id: number | null;
}

// export const schema: Yup.ObjectSchema<ProfileEditDataType> = Yup.object({
//   first_name: Yup.string().required(),
//   sur_name: Yup.string().required(),
//   prefix: Yup.string().required(),
//   gender: Yup.string().required(),
//   phone_no: Yup.string().required(),
//   email: Yup.string().required(),
//   address: Yup.object().shape(InvoiceAddressEditDataType),
//   address: Yup.string().required(),
//   occupation: Yup.string().required(),
//   company_name: Yup.string().required(),
// });

export const addressSchema: Yup.ObjectSchema<any> = Yup.object({
  address: Yup.string().required(),
  name: Yup.string().required(),
  invoice_number: Yup.string().required(),
  phone_no: Yup.string().required(),
  vat_license_image_url: Yup.string().required(),
});

export const UserEdit = () => {
  const paramString = useParams();
  // const { data, isLoading, isError } = useList<
  //   InvoiceAddressDataType,
  //   HttpError
  // >({
  //   resource: "invoice-addresses",
  // });
  // const invoiceAddresses = data?.data ?? [];
  const {
    saveButtonProps,
    refineCore: { formLoading },
    setValue,
    getValues,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<any, any, ProfileEditDataType>({
    mode: "onChange",
    refineCoreProps: {
      action: "edit",
      resource: "profiles",
      id: paramString.id,
    },
    // resolver: yupResolver(schema),
  });

  const { queryResult: profileResult } = useShow<ProfileQueryDataType>({
    resource: "profiles",
    id: paramString.id,
  });
  const { data: profileData, isLoading: profileLoding } = profileResult;
  const profile = profileData?.data;
  const { queryResult: userResult } = useShow<UserQueryDataType>({
    resource: "users",
    id: profile?.user_id,
  });
  // const { queryResult: data } = useShow<InvoiceAddressDataType, HttpError>({
  //   resource: "invoice-addresses",
  //   id: paramString.id,
  // });
  const { data } = useCustom({
    url: `${REST_PUBLIC_URI}/api/v1/admin/invoice-addresses/user/${profile?.user_id}`,
    method: "get",
    config: {
      headers: {
        "x-custom-header": "foo-bar",
      },
      // query: {
      //   user_id: paramString.id,
      // },
    },
  });

  const invoiceAddresses: InvoiceAddressDataType[] = data?.data.items;

  const invoiceAddressesUser = invoiceAddresses;

  // const invoiceAddressesUser = useMemo(() => {
  //   if (invoiceAddresses) {
  //     return invoiceAddresses.filter(
  //       (invoiceAddress) => invoiceAddress.user_id === profile?.user_id
  //     );
  //   }
  // }, [profile?.user_id, invoiceAddresses]);

  // const {
  //   saveButtonProps: addressSaveButtonProps,
  //   refineCore: { formLoading: addressFormLoading },
  //   setValue: addressSetValue,
  //   register: addressRegister,
  //   handleSubmit: addressHandleSubmit,
  //   control: addressControl,
  //   watch: addressWatch,
  //   formState: { errors: addressErrors },
  // } = useForm<any, any, InvoiceAddressEditDataType>({
  //   mode: "onChange",
  //   resolver: yupResolver(addressSchema),
  //   refineCoreProps: {
  //     action: "edit",
  //     resource: "address",
  //     id: invoiceAddressesUser
  //       ? invoiceAddressesUser[0]?.invoice_address_id
  //       : 0,
  //   },
  // });

  // const openImagePreview = (imageUrl: string) => {
  //   const newTab = window.open("", "_blank");
  //   newTab?.document.write(`<img src="${imageUrl}" />`);
  // };

  const appendAddress = (value: InvoiceAddressEditDataType) => {
    const addresses = getValues("address") || [];
    if (addresses === null) {
      setValue("address", [value]);
      return;
    }
    setValue("address", [...addresses, value]);
  };

  const removeAddress = (index: number) => {
    const Addresses = getValues("address") || [];
    setValue(
      "address",
      Addresses.filter((_, i) => i !== index)
    );
    return;
  };

  const moveAddress = (from: number, to: number) => {
    const addresses = getValues("address") || [];
    const addressCopy = [...addresses];
    const [removed] = addressCopy.splice(from, 1);
    addressCopy.splice(to, 0, removed);
    setValue("address", addressCopy);
  };

  return (
    <Edit
      title={
        <Typography variant="h5" color="textPrimary">
          แก้ไขข้อมูลผู้ใช้งาน
        </Typography>
      }
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Typography variant="subtitle1">รายละเอียด</Typography>
        <TextField
          {...register("user_id")}
          disabled
          required
          error={!!(errors as any)?.user_id}
          helperText={(errors as any)?.user_id?.message}
          margin="normal"
          fullWidth
          type="text"
          label="user id"
          InputLabelProps={{ shrink: true }}
          name="user_id"
        />

        <TextField
          {...register("profile_id")}
          required
          disabled
          error={!!(errors as any)?.profile_id}
          helperText={(errors as any)?.profile_id?.message}
          margin="normal"
          fullWidth
          type="text"
          label="profile id"
          InputLabelProps={{ shrink: true }}
          placeholder="ใส่ข้อความที่นี่"
          name="profile_id"
        />

        <TextField
          {...register("first_name", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.first_name}
          helperText={(errors as any)?.first_name?.message}
          margin="normal"
          fullWidth
          type="text"
          label="ชื่อ"
          InputLabelProps={{ shrink: true }}
          placeholder="ชื่อ"
          name="first_name"
        />
        <TextField
          {...register("sur_name", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.sur_name}
          helperText={(errors as any)?.sur_name?.message}
          margin="normal"
          fullWidth
          type="text"
          label="นามสกุล"
          InputLabelProps={{ shrink: true }}
          placeholder="นามสกุล"
          name="sur_name"
        />
        <div className="flex mt-2 gap-1 flex-col">
          <Typography variant="subtitle1" className="text-gray-500">
            คำนำหน้า
          </Typography>
          <Controller
            name="prefix"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                {...register("prefix")}
                onChange={(newValue) => onChange(newValue.target.value)}
              >
                <MenuItem value={"นาย"}>
                  <div>นาย</div>
                </MenuItem>
                <MenuItem value={"นาง"}>
                  <div>นาง</div>
                </MenuItem>
                <MenuItem value={"นางสาว"}>
                  <div>นางสาว</div>
                </MenuItem>
              </Select>
            )}
          />
        </div>
        <div className="flex mt-2 gap-1 flex-col">
          <Typography variant="subtitle1" className="text-gray-500">
            เพศ
          </Typography>
          <Controller
            name="gender"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                {...register("gender")}
                onChange={(newValue) => onChange(newValue.target.value)}
              >
                <MenuItem value={"ชาย"}>
                  <div>ชาย</div>
                </MenuItem>
                <MenuItem value={"หญิง"}>
                  <div>หญิง</div>
                </MenuItem>
                <MenuItem value={"LGBT"}>
                  <div>LGBT</div>
                </MenuItem>
              </Select>
            )}
          />
        </div>
        <TextField
          {...register("phone_no", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.phone_no}
          helperText={(errors as any)?.phone_no?.message}
          margin="normal"
          fullWidth
          type="text"
          label="เบอร์ติดต่อ"
          InputLabelProps={{ shrink: true }}
          placeholder="ใส่ข้อความที่นี่"
          name="phone_no"
        />

        <div className="py-1" />

        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          inputProps={{
            ...register(`birth_date` as const, {
              required: true,
            }),
          }}
          error={!!(errors as any)?.birth_date}
          helperText={(errors as any)?.birth_date?.message}
          type="date"
          label="วันเกิด"
          variant="outlined"
          name={`birth_date`}
        />

        <div className="py-1" />

        <TextField
          {...register("personal_id", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.personal_id}
          helperText={(errors as any)?.personal_id?.message}
          margin="normal"
          fullWidth
          type="text"
          label="เลขบัตรประชาชน"
          InputLabelProps={{ shrink: true }}
          placeholder="ใส่ข้อความที่นี่"
          name="personal_id"
        />

        <div className="py-1" />

        <TextField
          {...register("company_name", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.company_name}
          helperText={(errors as any)?.company_name?.message}
          margin="normal"
          fullWidth
          type="text"
          label="บริษัท"
          InputLabelProps={{ shrink: true }}
          placeholder="ใส่ข้อความที่นี่"
          name="company_name"
        />

        <TextField
          {...register("email", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.email}
          helperText={(errors as any)?.email?.message}
          margin="normal"
          fullWidth
          type="text"
          label="อีเมล"
          InputLabelProps={{ shrink: true }}
          placeholder="ใส่ข้อความที่นี่"
          name="email"
        />
        <TextField
          {...register("address", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.address}
          helperText={(errors as any)?.address?.message}
          margin="normal"
          fullWidth
          type="text"
          label="ที่อยู่"
          InputLabelProps={{ shrink: true }}
          placeholder="ใส่ข้อความที่นี่"
          name="address"
        />

        <div className="flex mt-2 gap-1 flex-col">
          <Typography variant="subtitle1" className="text-gray-500">
            อาชีพ
          </Typography>
          <Controller
            name="occupation"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                select
                label={watch("occupation") === "" ? "อาชีพ" : ""}
                value={value}
                onChange={(value) => {
                  onChange(value);
                  if (value.target.value !== "ETC") {
                    setValue("custom_occupation", null as unknown as string);
                  }
                }}
                InputLabelProps={{ shrink: false }}
              >
                <MenuItem value="STUDENT">นักเรียน / นักศึกษา</MenuItem>
                <MenuItem value="GOV_OFFICER">รับราชการ / ข้าราชการ</MenuItem>
                <MenuItem value="OFFICE_WORKER">พนักงานบริษัทเอกชน</MenuItem>
                <MenuItem value="ENGINEER">วิศวกร</MenuItem>
                <MenuItem value="ARCHITECT">สถาปนิก</MenuItem>
                <MenuItem value="BUSINESS_OWNER">
                  เจ้าของกิจการ / ธุรกิจส่วนตัว
                </MenuItem>
                <MenuItem value="FREELANCE">รับจ้างอิสระ / Freelance</MenuItem>
                <MenuItem value="ETC">อื่นๆ</MenuItem>
              </TextField>
            )}
          />
        </div>

        <div className="mt-4" />

        <TextField
          {...register("custom_occupation")}
          label="อาชีพที่กำหนดเอง"
          InputLabelProps={{ shrink: true }}
        />

        <div className="flex mt-2 gap-1 flex-col">
          <Typography variant="subtitle1" className="text-gray-500">
            ใช้ที่อยู่เดียวกับโปรไฟล์
          </Typography>
          <Controller
            name="invoice_same_as_profile"
            control={control}
            defaultValue={true}
            render={({ field: { onChange, value } }) => (
              <TextField
                select
                label={watch("occupation") === "" ? "อาชีพ" : ""}
                value={value}
                {...register("invoice_same_as_profile")}
                onChange={(newValue) => {
                  onChange(newValue.target.value as any);
                  if (newValue.target.value) {
                    setValue("primary_invoice", null);
                  }
                }}
              >
                <MenuItem value={true as any}>
                  <div>ใช่</div>
                </MenuItem>
                <MenuItem value={false as any}>
                  <div>ไม่ใช่</div>
                </MenuItem>
              </TextField>
            )}
          />
        </div>

        <div className="flex mt-2 gap-1 flex-col">
          <Typography variant="subtitle1" className="text-gray-500">
            ที่อยู่สำหรับออกใบเสร็จ
          </Typography>
          <Controller
            name="primary_invoice"
            control={control}
            defaultValue={null}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                {...register("primary_invoice")}
                onChange={(newValue) =>
                  onChange(newValue.target.value as number)
                }
              >
                {invoiceAddressesUser?.map((addr) => (
                  <MenuItem
                    key={addr.invoice_address_id}
                    value={addr.invoice_address_id}
                  >
                    {addr.invoice_address_id}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </div>
        {/* <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {invoiceAddressesUser &&
                  invoiceAddressesUser.map((address, index) => (
                    <>
                      <Controller
                        name="address"
                        control={addressControl}
                        defaultValue={"เซลล์"}
                        render={({ field: { onChange, value } }) => (
                          <FormEditAddress
                            key={index}
                            invoiceAddress={address}
                            addressIndex={index}
                            control={addressControl}
                            errors={addressErrors}
                            register={addressRegister}
                            setValue={addressSetValue}
                            watch={addressWatch}
                          />
                        )}
                      />
                    </>
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext> */}

        {/* <div className="py-6">
          <div>
            <Typography variant="subtitle1" className="text-gray-500">
              ระบุที่อยู่
            </Typography>
            <FormItem className="">
              <FormEditAddressList
                append={appendAddress}
                control={addressControl}
                register={addressRegister}
                remove={removeAddress}
                move={moveAddress}
                setValue={addressSetValue}
                errors={addressErrors}
                watch={addressWatch}
              />
            </FormItem>
          </div>
        </div> */}

        <div className="py-4" />

        <ShowButton
          resourceNameOrRouteName="invoice-addresses/user"
          recordItemId={profile?.user_id.toString() || ""}
          variant="outlined"
        >
          แก้ไขที่อยู่สำหรับออกใบเสร็จ
        </ShowButton>
      </Box>
    </Edit>
  );
};
