import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  EditButton,
  List,
  DeleteButton,
} from "@pankod/refine-mui";

export const ManualPaymentsList = () => {
  const { dataGridProps } = useDataGrid({
    queryOptions: {
      retry: 1,
    },
  });

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "actions",
        headerName: "Action",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
      },
      {
        field: "id",
        headerName: "Id",
        minWidth: 250,
        type: "number",
        valueFormatter: ({ value }) =>
          value.toLocaleString("en-US", { useGrouping: false }),
        headerAlign: "left",
        align: "left",
        sortable: false,
      },
      {
        field: "purchase_history_id",
        headerName: "Purchase History Id (refId)",
        minWidth: 250,
        type: "number",
        valueFormatter: ({ value }) =>
          value.toLocaleString("en-US", { useGrouping: false }),
        headerAlign: "left",
        align: "left",
        sortable: false,
      },
      {
        field: "amount",
        headerName: "Amount",
        minWidth: 50,
        type: "number",
        headerAlign: "left",
        align: "left",
        sortable: false,
      },
      {
        field: "transaction_id",
        headerName: "Transaction Id",
        minWidth: 50,
        type: "number",
        headerAlign: "left",
        align: "left",
        sortable: false,
      },
      {
        field: "ref1",
        headerName: "Ref1",
        minWidth: 200,
        headerAlign: "left",
        align: "left",
        sortable: false,
      },
      {
        field: "ref2",
        headerName: "Ref2",
        minWidth: 200,
        headerAlign: "left",
        align: "left",
        sortable: false,
      },
      {
        field: "ref3",
        headerName: "Ref3",
        minWidth: 200,
        headerAlign: "left",
        align: "left",
        sortable: false,
      },

      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 250,
        headerAlign: "left",
        align: "left",
        sortable: false,
      },

      {
        field: "updated_at",
        headerName: "Updated At",
        minWidth: 250,
        headerAlign: "left",
        align: "left",
        sortable: false,
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        getRowId={(row) => row.id}
        columns={columns}
        autoHeight
        disableColumnFilter={true}
      />
    </List>
  );
};
