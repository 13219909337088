import { Create, Box, TextField } from "@pankod/refine-mui";
import {
  Controller,
  useFieldArray,
  useForm,
} from "@pankod/refine-react-hook-form";
import { AxiosInstance } from "axios";
import { axiosInstance } from "@pankod/refine-simple-rest";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState } from "react";

import { REST_PUBLIC_URI } from "environment";

export interface PaymentsDataCreate {
  purchase_history_id: number;
  amount: number;
  transaction_id: string | undefined;
  ref1: string | undefined;
  ref2: string | undefined;
  ref3: string | undefined;
}

export const schema = Yup.object({
  purchase_history_id: Yup.number().required(),
  amount: Yup.number().min(0, "Amount must be greater than 0").required(),
  transaction_id: Yup.string(),
  ref1: Yup.string(),
  ref2: Yup.string(),
  ref3: Yup.string(),
});
export const ManualPaymentsCreate = () => {
  const {
    saveButtonProps,
    refineCore: { formLoading },
    setValue,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<any, any, PaymentsDataCreate>({
    mode: "onChange",
    defaultValues: {
      ref1: "",
      ref2: "",
      ref3: "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("purchase_history_id", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.purchase_history_id}
          helperText={(errors as any)?.purchase_history_id?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          label="purchase_history_id"
          name="purchase_history_id"
        />

        <div className="flex gap-4">
          <TextField
            {...register("amount", {
              required: "This field is required",
              min: {
                value: 0,
                message: "Amount must be greater than or equal to 0",
              },
            })}
            error={!!(errors as any)?.amount}
            helperText={(errors as any)?.amount?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="number"
            label="amount"
            placeholder="0"
            name="amount"
          />
          <TextField
            {...register("transaction_id")}
            error={!!(errors as any)?.transaction_id}
            helperText={(errors as any)?.transaction_id?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="transaction_id"
            name="transaction_id"
          />
        </div>

        <TextField
          {...register("ref1")}
          error={!!(errors as any)?.ref1}
          helperText={(errors as any)?.ref1?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Ref1"
          name="ref1"
        />

        <TextField
          {...register("ref2")}
          error={!!(errors as any)?.ref2}
          helperText={(errors as any)?.ref2?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Ref2"
          name="ref2"
        />

        <TextField
          {...register("ref3")}
          error={!!(errors as any)?.ref3}
          helperText={(errors as any)?.ref3?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Ref3"
          name="ref3"
        />
      </Box>
    </Create>
  );
};
