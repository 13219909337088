import React from "react";
import {
  useNavigation,
  useResourceWithRoute,
  useRouterContext,
  useTranslate,
  userFriendlyResourceName,
  ResourceRouterParams,
  useRefineContext,
} from "@pankod/refine-core";
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  IconButton,
  Typography,
  Box,
  Breadcrumb,
  SaveButton,
} from "@pankod/refine-mui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import type { CreateProps } from "@pankod/refine-mui";

/**
 * `<Create>` provides us a layout to display the page.
 * It does not contain any logic but adds extra functionalities like action buttons and giving titles to the page.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/mui/components/basic-views/create} for more details.
 */
export const Create: React.FC<CreateProps> = ({
  title,
  actionButtons,
  children,
  saveButtonProps,
  resource: resourceFromProps,
  isLoading = false,
  cardProps,
  cardHeaderProps,
  cardContentProps,
  cardActionsProps,
  breadcrumb: breadcrumbFromProps,
  wrapperProps,
  headerProps,
  contentProps,
  headerButtonProps,
  headerButtons,
  footerButtonProps,
  footerButtons,
  goBack: goBackFromProps,
}) => {
  const { goBack } = useNavigation();

  const translate = useTranslate();

  const { useParams } = useRouterContext();

  const { resource: routeResourceName, action: routeFromAction } =
    useParams<ResourceRouterParams>();

  const resourceWithRoute = useResourceWithRoute();

  const resource = resourceWithRoute(resourceFromProps ?? routeResourceName);

  const { options } = useRefineContext();
  const breadcrumb =
    typeof breadcrumbFromProps === "undefined"
      ? options?.breadcrumb
      : breadcrumbFromProps;

  const breadcrumbComponent =
    typeof breadcrumb !== "undefined" ? (
      <>{breadcrumb}</> ?? undefined
    ) : (
      <Breadcrumb />
    );

  const defaultFooterButtons = (
    <SaveButton
      {...(isLoading ? { disabled: true } : {})}
      {...saveButtonProps}
    />
  );

  return (
    <Card {...(cardProps ?? {})} {...(wrapperProps ?? {})}>
      {breadcrumbComponent}
      <CardHeader
        sx={{ display: "flex", flexWrap: "wrap" }}
        title={
          title ?? (
            <Typography variant="h5">
              {translate(
                `${resource.name}.titles.create`,
                `Create ${userFriendlyResourceName(
                  resource.label ?? resource.name,
                  "singular"
                )}`
              )}
            </Typography>
          )
        }
        avatar={
          typeof goBackFromProps !== "undefined" ? (
            goBackFromProps
          ) : (
            <IconButton onClick={routeFromAction ? goBack : undefined}>
              <ArrowBackIcon />
            </IconButton>
          )
        }
        action={
          headerButtons ? (
            <Box display="flex" gap="16px" justifyItems={"center"} {...(headerButtonProps ?? {})}>
              {headerButtons
                ? typeof headerButtons === "function"
                  ? headerButtons({
                      defaultButtons: null,
                    })
                  : headerButtons
                : null}
            </Box>
          ) : null
        }
        {...(cardHeaderProps ?? {})}
        {...(headerProps ?? {})}
      />
      <CardContent {...(cardContentProps ?? {})} {...(contentProps ?? {})}>
        {children}
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "16px",
          padding: "16px",
        }}
        {...(cardActionsProps ?? {})}
        {...(footerButtonProps ?? {})}
      >
        {footerButtons
          ? typeof footerButtons === "function"
            ? footerButtons({
                defaultButtons: defaultFooterButtons,
              })
            : footerButtons
          : actionButtons
          ? actionButtons
          : null}
      </CardActions>
    </Card>
  );
};
