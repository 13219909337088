import { CourseQueryDataType } from "./show";
import {
  Button,
  DataGrid,
  GridColumns,
  ShowButton,
  useDataGrid,
} from "@pankod/refine-mui";
import { useShow } from "@pankod/refine-core";
import IconDownload from "components/SvgIcons/IconDownload";
import { useMemo, useRef, useState } from "react";
import { formatDateTime24Hr, formatYearMonthDay } from "utils/util";
import { AxiosInstance } from "axios";
import { axiosInstance } from "@pankod/refine-simple-rest";
import { REST_PUBLIC_URI } from "environment";
import { ProfileQueryDataType } from "pages/users";
import { useParams } from "@pankod/refine-react-router-v6";
import { CsvSchema } from "types/general";

interface Enrollment {
  enrollment_id: number;
  course_id: number;
  course_name: string;
  user_id: number;
  username: string;
  question: string[];
  course_infos: string[];
  status: string;
  invoice_id: number;
  invoice_url: string;
  invoice_price: number;
  created_at: Date;
  updated_at: Date;
  purchase_history_id: null;
  success_at: null;
  cancel_at: null;
  row_number: number;
}

const additionalSchema: CsvSchema[] = [
  {
    headerName: "สมัครด้วย",
    field: "register_by",
  },
];

const profileSchema: CsvSchema<ProfileQueryDataType>[] = [
  {
    headerName: "image url",
    field: "image_url",
  },
  {
    headerName: "name title",
    field: "prefix",
  },
  {
    headerName: "firstname",
    field: "first_name",
  },
  {
    headerName: "surname",
    field: "sur_name",
  },
  {
    headerName: "gender",
    field: "gender",
  },
  {
    headerName: "birth date",
    field: "birth_date",
  },
  {
    headerName: "personal id",
    field: "personal_id",
  },
  {
    headerName: "phone no",
    field: "phone_no",
  },
  {
    headerName: "email",
    field: "email",
  },
  {
    headerName: "address",
    field: "address",
  },
  {
    headerName: "occupation",
    field: "occupation",
  },
  {
    headerName: "custom occupation",
    field: "custom_occupation",
  },
  {
    headerName: "company name",
    field: "company_name",
  },
  {
    headerName: "invoice address same as profile",
    field: "invoice_same_as_profile",
    formatter: (v) => (v ? "yes" : "no"),
  },
  {
    headerName: "raw invoice address",
    field: "primary_invoice_address",
    formatter: (v) => {
      delete v.invoice_address_id;
      delete v.user_id;
      delete v.created_at;
      delete v.updated_at;
      return JSON.stringify(v)
        .replaceAll(",", ";")
        .replace("{", "")
        .replace("}", "");
    },
  },
  {
    headerName: "invoice address",
    field: "primary_invoice_address",
    formatter: (v) => {
      delete v.invoice_address_id;
      delete v.user_id;
      delete v.created_at;
      delete v.updated_at;
      return `ชื่อ ${v.name || ""} ที่อยู่ ${
        v.address
      } หมายเลขประจําตัวผู้เสียภาษีอากร ${v.invoice_number} โทร ${v.phone_no}`;
    },
  },
  {
    headerName: "ภ.พ. 20",
    field: "primary_invoice_address",
    formatter: (v) => {
      delete v.invoice_address_id;
      delete v.user_id;
      delete v.created_at;
      delete v.updated_at;
      return v.vat_license_image_url || "";
    },
  },
];

interface CsvContent
  extends Omit<Enrollment, "created_at" | "updated_at">,
    ProfileQueryDataType {}

interface Props {
  courseData?: CourseQueryDataType;
}

interface data {
  file_url: string;
}
interface fileUpload {
  data: data;
}

const CoursePayment = ({ courseData }: Props) => {
  const [fetchProps, setFetchProps] = useState("");
  const paramString = useParams();
  const { dataGridProps } = useDataGrid({
    resource: "enrollments/enrollment/payment",
    permanentFilter: [
      {
        field: "course_id",
        operator: "eq",
        value: courseData?.course_id,
      },
      {
        field: "status",
        operator: "eq",
        value: "SUCCESS",
      },
      {
        field: "",
        operator: "contains",
        value: fetchProps,
      },
    ],
    queryOptions: {
      retry: 1,
    },
  });

  dataGridProps.rows.forEach((item, index) => {
    item.row_number = index + 1;
  });

  const { queryResult: profileResult } = useShow<ProfileQueryDataType[]>({
    resource: "profiles/course",
    id: paramString.id,
  });

  const enrollData = dataGridProps.rows;
  const profileData = profileResult?.data?.data;

  let DataMapping = enrollData.map((enroll) => {
    let profile = profileData?.find(
      (profile: any) => profile.user_id === enroll.user_id
    );
    return { ...enroll, ...profile };
  });

  const [file, setFile] = useState();
  const [selectId, setSelectId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);

  const inputRef = useRef<Record<number, HTMLInputElement>>({});
  const handleClick = (enrollmentId: number) => {
    inputRef?.current[enrollmentId]?.click();
  };

  const handleFileChange = async (
    file: File | undefined,
    enrollmentId: number
  ) => {
    // setFile(event.target.files[0]);
    if (!file) {
      return;
    }

    setLoading(true);
    try {
      const file_url = await upload(file);
      await handleUpdateInvoice(enrollmentId, file_url.data.file_url);
      setLoading(false);
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        window.alert(error.response.data.detail);
      }
      setLoading(false);
    }
  };

  const handleRefetch = () => {
    setFetchProps((prev) => prev + "0");
  };

  const handleUpdateInvoice = async (enrollmentId: number, fileUrl: string) => {
    const url = `${REST_PUBLIC_URI}/api/v1/admin/invoices/quick_invoice`;
    const res = await axiosInstance.post(url, {
      enrollment_id: enrollmentId,
      invoice_url: fileUrl,
    });
    await handleRefetch();
  };

  const upload = async (base64: File) => {
    const x = new FormData();
    x.append("file", base64);
    const httpClient: AxiosInstance = axiosInstance;
    const url = `${REST_PUBLIC_URI}/api/v1/admin/files/upload_file`;
    const data: fileUpload = await httpClient.post(url, x);
    return data;
  };

  // const onUploadBanner = async (file: File) => {
  //   setLoading(true);
  //   try {
  //     const file_url = await upload(file);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (file) onUploadBanner(file);
  // }, [file]);

  const questionsColumns = useMemo<GridColumns<any>>(() => {
    const questions: string[] = dataGridProps.rows[0]?.question || [];
    const questionColumns = questions.map((question, index) => ({
      field: `question_${index}`,
      headerName: question,
      minWidth: 150,
      valueGetter: function (params: { row: any }) {
        return params.row.course_infos[index];
      },
    }));

    return questionColumns;
  }, [dataGridProps.rows]);

  const columns = useMemo<GridColumns<any>>(
    () => [
      {
        field: "actions",
        headerName: "ดูข้อมูล",
        renderCell: function render({ row }) {
          return (
            <>
              <ShowButton
                hideText
                recordItemId={row.enrollment_id}
                resourceNameOrRouteName="enrollments"
              />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
      },
      {
        field: "row_number",
        headerName: "No.",
        renderCell: function render({ value }) {
          return value;
        },
        align: "left",
        headerAlign: "left",
        minWidth: 80,
        sortable: false,
      },
      {
        field: "user_id",
        headerName: "User ID",
        minWidth: 150,
      },
      {
        field: "username",
        headerName: "Account Name",
        minWidth: 200,
      },
      {
        field: "success_at",
        headerName: "จ่ายเงินสำเร็จเมื่อ",
        minWidth: 200,
        renderCell: (params) => {
          const successAt = params.value as string;
          return successAt ? formatDateTime24Hr(successAt) : "-";
        },
      },
      {
        field: "purchase_history_id",
        headerName: "Purchase History Id (refId)",
        minWidth: 200,
      },
      ...questionsColumns,

      {
        field: "invoice",
        headerName: "ใบเสร็จ/ใบกำกับภาษี",
        renderCell: function render({ row }) {
          return (
            <div className="flex gap-2">
              <Button
                variant="contained"
                color="secondary"
                disabled={!row.invoice_url}
                href={row.invoice_url}
                target="_blank"
              >
                ดูไฟล์
              </Button>
              <div>
                <input
                  style={{ display: "none" }}
                  ref={(ref) =>
                    (inputRef.current[row.enrollment_id] =
                      ref as HTMLInputElement)
                  }
                  type="file"
                  accept="application/pdf"
                  onChange={(e) =>
                    handleFileChange(e.target.files?.[0], row.enrollment_id)
                  }
                  key={`upload_pdf_${row.enrollment_id}`}
                />

                <Button
                  variant="contained"
                  onClick={() => {
                    handleClick(row.enrollment_id);
                    setSelectId(row.enrollment_id);
                  }}
                  disabled={loading}
                >
                  อัพโหลดรูป
                </Button>
              </div>
            </div>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 200,
        sortable: false,
      },
    ],
    [questionsColumns]
  );

  const profileMapper = useMemo(() => {
    let mapper: Map<number, ProfileQueryDataType> = new Map();
    if (profileResult.data?.data) {
      mapper = profileResult.data.data.reduce((mapAccumulator, obj) => {
        mapAccumulator.set(obj["user_id"], obj);

        return mapAccumulator;
      }, new Map());
    }

    return mapper;

    // const r: CsvContent[] = [];

    // for (const row of dataGridProps.rows) {
    //   r.push({
    //     ...row,
    //     ...mapper.get(row.user_id),
    //   });
    // }

    // console.log(r);

    // return r;
  }, [profileResult, dataGridProps.rows]);

  const handleDownload = () => {
    const BOM = "\uFEFF";
    const csvContent = generateCsvContent();
    const textEncoder = new TextEncoder();
    const csvData = textEncoder.encode(BOM + csvContent);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Roster_${courseData?.course_id}_${formatYearMonthDay(
      new Date()
    )}.csv`;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateCsvContent = () => {
    let headerRow = columns
      .filter(
        (column) => column.field !== "actions" && column.field !== "invoice"
      )
      .map((column) => `"${column.headerName}"`);

    headerRow = headerRow.concat(additionalSchema.map((ps) => ps.headerName));
    headerRow = headerRow.concat(["price"]);
    headerRow = headerRow.concat(profileSchema.map((ps) => ps.headerName));

    const dataRows = dataGridProps.rows.map((row) => {
      let rowData = columns
        .map((column) => {
          if (column.field === "user_id") {
            return `'${row[column.field] || ""}`;
          } else if (column.field === "purchase_history_id") {
            return `"${
              row[column.field] !== null ? `'${row[column.field]}` : "-"
            }"`;
          } else if (column.field.startsWith("question_")) {
            const questionIndex = parseInt(
              column.field.replace("question_", ""),
              10
            );
            return `"${row.course_infos[questionIndex] || ""}"`;
          } else if (column.field === "phone_no") {
            return `'${row[column.field] || ""}`;
          }
          return `"${row[column.field] || ""}"`;
        })
        .filter((_, index) => columns[index].field !== "actions");
      // .join(",");

      rowData.pop();

      rowData.push(`"'${row.register_by}"`);
      // add final price
      rowData.push(`"${row.invoice_price}"`);

      const profile = profileMapper.get(row.user_id);

      for (const schema of profileSchema) {
        if (!profile) {
          rowData.push(`"-"`);
          continue;
        }

        const value = profile[schema.field];

        if (value === undefined || value === null) {
          rowData.push(`"-"`);
          continue;
        }

        if (schema.formatter) {
          rowData.push(`"${schema.formatter(value)}"`);
          continue;
        }

        if (typeof value === "number") {
          rowData.push(`"'${value}"`);
          continue;
        }

        if (/^\d+$/.test(value as string)) {
          rowData.push(`"'${value}"`);
          continue;
        }

        rowData.push(`"${value}"`);
      }

      return rowData.join(",");
    });

    const csvContent = [headerRow.join(","), ...dataRows].join("\n");

    return csvContent;
  };

  return (
    <>
      <div className="px-4 py-7">
        <span className="text-gray-500 mr-8" style={{ fontSize: "16px" }}>
          ดาวน์โหลดไฟล์
        </span>
        <Button
          onClick={handleDownload}
          variant="contained"
          startIcon={<IconDownload />}
        >
          Roster
        </Button>
      </div>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        getRowId={(row) => row.enrollment_id}
        autoHeight
      />
    </>
  );
};

export default CoursePayment;
