import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  EditButton,
  List,
  ShowButton,
  Button,
} from "@pankod/refine-mui";
import { useExport } from "@pankod/refine-core";

export const ProfileList = () => {
  const { triggerExport } = useExport();
  const { dataGridProps } = useDataGrid({
    queryOptions: {
      retry: 1,
    },
  });

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "actions",
        headerName: "Action",
        renderCell: function render({ row }) {
          return (
            <>
              <ShowButton
                hideText
                recordItemId={row.profile_id}
                disabled={!row.profile_id}
                resourceNameOrRouteName="users"
                id={row.profile_id}
              />
              <EditButton
                hideText
                recordItemId={row.profile_id}
                disabled={!row.profile_id}
                resourceNameOrRouteName="users"
                id={row.profile_id}
              />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
      },
      {
        field: "No.",
        headerName: "No.",
        minWidth: 20,
        type: "number",
        headerAlign: "left",
        align: "left",
        renderCell: (index: any) =>
          index.api.getRowIndex(index.row.user_id) + 1,
      },
      {
        field: "user_id",
        headerName: "User ID",
        minWidth: 200,
        type: "number",
        valueFormatter: ({ value }) =>
          value.toLocaleString("en-US", { useGrouping: false }),
        headerAlign: "left",
        align: "left",
      },

      {
        field: "email",
        headerName: "Email",
        minWidth: 200,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "prefix",
        headerName: "Prefix",
        minWidth: 20,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "first_name",
        headerName: "First Name",
        minWidth: 200,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "sur_name",
        headerName: "Last Name",
        minWidth: 200,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "phone_no",
        headerName: "Phone No",
        minWidth: 120,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "gender",
        headerName: "Gender",
        minWidth: 10,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "birth_date",
        headerName: "Birth Date",
        minWidth: 100,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "occupation",
        headerName: "Occupation",
        minWidth: 200,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "address",
        headerName: "Address",
        minWidth: 600,
        headerAlign: "left",
        align: "left",
      },
    ],
    []
  );

  return (
    <List
      headerButtons={
        <div>
          <Button onClick={triggerExport} variant="outlined">
            export
          </Button>
        </div>
      }
    >
      <DataGrid
        {...dataGridProps}
        getRowId={(row) => row.user_id}
        columns={columns}
        autoHeight
        disableColumnFilter={true}
      />
    </List>
  );
};
