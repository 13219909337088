import { LightTheme } from "@pankod/refine-mui";

const OverridedLightTheme = {
    ...LightTheme,
    palette: {
        ...LightTheme.palette,
        secondary: {
            main: "#5335BA",
            contrastText: "#FFFFFF"
        },
    },
};

export default OverridedLightTheme