import React from "react";
import "index.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
  ReadyPage,
} from "@pankod/refine-mui";
import authProvider from "authProvider";
import dataProvider from "dataProvider";
import { MuiInferencer } from "@pankod/refine-inferencer/mui";
import routerProvider from "@pankod/refine-react-router-v6";
import { Layout } from "components/layout";
import { REST_PUBLIC_URI } from "environment";
import { AuthPage } from "components/pages/auth";
import { CourseEdit, CourseList, CourseCreate, CourseShow } from "pages/course";
import {
  ManualPaymentsCreate,
  ManualPaymentsList,
  ManualPaymentsEdit,
} from "pages/manual_payments";
import {
  CourseDiscountCreate,
  CourseDiscountList,
  CourseDiscountEdit,
} from "pages/course_discount";
import OverridedLightTheme from "themeProvider";
import { EnrollmentShow, EnrollmentsList } from "pages/enrollments";
import {
  LecturerCreate,
  LecturerList,
  LecturerEdit,
  LecturerShow,
} from "pages/lecturer";
import { NewsCreate, NewsEdit, NewsList } from "pages/news";
// import { TaxInvoiceEdit, TaxInvoiceList } from "pages/invoices";
import { UserEdit, UserList, UserShow } from "pages/users";
import { ProfileCreate, ProfileList } from "pages/profiles";
import { InvoiceAddressShow } from "pages/invoices_addresses/show";

function App() {
  return (
    <ThemeProvider theme={OverridedLightTheme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <Refine
          dataProvider={dataProvider(`${REST_PUBLIC_URI}/api/v1/admin`)}
          authProvider={authProvider}
          routerProvider={{
            ...routerProvider,
            routes: [{ path: "/login", element: <AuthPage type="login" /> }],
          }}
          notificationProvider={notificationProvider}
          Layout={Layout}
          ReadyPage={ReadyPage}
          LoginPage={AuthPage}
          resources={[
            { name: "course", options: { label: "หลักสูตร" } },
            {
              name: "courses",
              list: CourseList,
              show: CourseShow,
              create: CourseCreate,
              edit: CourseEdit,
              canDelete: false,
              parentName: "course",
              options: { label: "หลักสูตรทั้งหมด" },
            },
            {
              name: "course_discounts",
              list: CourseDiscountList,
              show: MuiInferencer,
              create: CourseDiscountCreate,
              edit: CourseDiscountEdit,
              canDelete: false,
              parentName: "course",
              options: { label: "ส่วนลด" },
            },
            { name: "member", options: { label: "สมาชิก" } },
            {
              name: "users",
              show: UserShow,
              list: UserList,
              edit: UserEdit,
              canDelete: false,
              parentName: "member",
              options: { label: "ผู้ใช้งานทั้งหมด" },
            },
            {
              name: "profiles",
              list: ProfileList,
              create: ProfileCreate,
              canDelete: false,
              parentName: "member",
              options: { label: "สมาชิกทั้งหมด" },
            },
            {
              name: "lecturers",
              list: LecturerList,
              create: LecturerCreate,
              edit: LecturerEdit,
              show: LecturerShow,
              canDelete: false,
              parentName: "member",
              options: { label: "วิทยากรทั้งหมด" },
            },
            {
              name: "invoice-addresses/user",
              show: InvoiceAddressShow,
              options: { hide: true },
            },
            {
              name: "enrollments",
              list: EnrollmentsList,
              show: EnrollmentShow,
              // edit: EnrollmentEdit,
              // create: CourseCreate,
              canDelete: false,
              parentName: "member",
              options: { hide: true },
            },
            // {
            //   name: "purchase_histories",
            //   list: PurchaseHistoryList,
            //   show: MuiInferencer,
            //   // create: CourseCreate,
            //   edit: PurchaseHistoryEdit,
            //   canDelete: false,
            // },
            { name: "payment", options: { label: "การชำระเงิน" } },

            // {
            //   name: "invoices",
            //   list: TaxInvoiceList,
            //   edit: TaxInvoiceEdit,
            //   parentName: "payment",
            //   options: { label: "ใบเสร็จ/ใบกำกับภาษี" },
            // },
            {
              name: "manual_payments",
              list: ManualPaymentsList,
              show: MuiInferencer,
              create: ManualPaymentsCreate,
              edit: ManualPaymentsEdit,
              canDelete: false,
              parentName: "payment",
              options: { label: "จัดการคำขอ" },
            },
            { name: "announce", options: { label: "ประกาศ" } },
            {
              name: "news",
              list: NewsList,
              create: NewsCreate,
              edit: NewsEdit,
              canDelete: false,
              parentName: "announce",
              options: { label: "ข่าวประชาสัมพันธ์" },
            },
          ]}
        />
      </RefineSnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
