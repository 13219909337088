import React, { useState } from "react";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Tab, Tabs, Typography } from "@pankod/refine-mui";
import { CourseTime } from "utils/util";
import CourseDetail from "./CourseDetail";
import { FormDiscountCreate, FormItemData } from "./create";
import CoursePayment from "./CoursePayment";
import CourseMember from "./CourseMember";
import { LecturerQueryDataType } from "pages/lecturer";

export interface CourseQueryDataType {
  course_id: string;
  name: string;
  tag_line: string;
  description: string;
  course_times: CourseTime[];
  location: string;
  start_at: string;
  close_at: string;
  price: number;
  course_discount: FormDiscountCreate;
  course_infos: FormItemData[];
  banner_url: string;
  tags: string[];
  lecturer: LecturerQueryDataType;
  lecturer_id: number;
}

export const CourseShow: React.FC<IResourceComponentsProps> = () => {
  const [tab, setTab] = useState(0);
  const { queryResult } = useShow<CourseQueryDataType>();
  const { data, isLoading } = queryResult;
  const course = data?.data;

  return (
    <Show
      isLoading={isLoading}
      title={
        <Typography variant="h5" color="textPrimary">
          {course?.name}
        </Typography>
      }
    >
      <Tabs
        centered
        onChange={(_, value) => setTab(value)}
        value={tab}
        variant="fullWidth"
      >
        <Tab label="รายละเอียดหลักสูตร" style={{ fontSize: "16px" }} />
        <Tab label="รายชื่อผู้ลงทะเบียน" style={{ fontSize: "16px" }} />
        <Tab label="รายชื่อผู้ชำระเงิน" style={{ fontSize: "16px" }} />
      </Tabs>
      {tab === 0 && <CourseDetail courseData={course} />}
      {tab === 1 && <CourseMember courseData={course} />}
      {tab === 2 && <CoursePayment courseData={course} />}
    </Show>
  );
};
