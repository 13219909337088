import {
  Box,
  Button,
  FormControlLabel,
  LoadingButton,
  SaveButton,
  Switch,
  TextField,
  Typography,
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { AxiosInstance } from "axios";
import { axiosInstance } from "@pankod/refine-simple-rest";
import ModalImageCrop from "components/Modals/ModalImageCrop";
import useModalState from "hooks/useModalState";
import { useState } from "react";
import { REST_PUBLIC_URI } from "environment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Create } from "components/crud/create";
import { useDropzone } from "react-dropzone";
import { BsImage } from "react-icons/bs";
import { Editor } from "react-draft-wysiwyg";
import BlogPreview from "components/BlogPreview";
import { EditorState, convertToRaw } from "draft-js";

export interface FormDataCreateBlog {
  banner_url?: string;
  title: string;
  short_dsc: string;
  content: [{}];
  is_public: boolean;
}

interface data {
  file_url: string;
}

interface fileUpload {
  data: data;
}

export const NewsCreate = () => {
  const { isShow, onClose, onOpen } = useModalState();

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormDataCreateBlog>({
    mode: "onChange",
  });

  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [contentState, setContentState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState<File[]>();
  const [croppedFile, setCroppedFile] = useState<File>();
  const onDrop = async (droppedFiles: File[]) => {
    setFiles(droppedFiles);
    onOpen();
  };
  const upload = async (base64: File) => {
    const x = new FormData();
    x.append("file", base64);
    const httpClient: AxiosInstance = axiosInstance;
    const url = `${REST_PUBLIC_URI}/api/v1/admin/files/upload_file`;
    const data: fileUpload = await httpClient.post(url, x);
    return data;
  };

  const onUploadBanner = async (file: File) => {
    const file_url = await upload(file);
    setValue("banner_url", file_url?.data?.file_url);
    setCroppedFile(file);
    onClose();
  };
  const watchBanner = watch("banner_url");
  const watchValues = watch();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
  });
  const removeImg = () => {
    setFiles(undefined);
    setCroppedFile(undefined);
    setValue("banner_url", undefined);
  };

  const uploadImageCallBack = (file: File, callback: any) => {
    return new Promise((resolve) => {
      const reader = new window.FileReader();
      reader.onloadend = async () => {
        const res = await upload(file);
        resolve({ data: { link: res.data.file_url } });
      };
      reader.readAsDataURL(file);
    });
  };

  const onEditorStateChange = function (contentState: any) {
    setContentState(contentState);
    const rawContent = convertToRaw(contentState.getCurrentContent());
    setValue("content", [rawContent]);
  };

  return (
    <Create
      title={
        <Typography variant="h5" color="textPrimary">
          สร้าง News
        </Typography>
      }
      isLoading={formLoading}
      headerButtons={
        <>
          <LoadingButton
            {...(formLoading ? { disabled: true } : {})}
            startIcon={isPreview ? <VisibilityOffIcon /> : <VisibilityIcon />}
            sx={{ minWidth: 0 }}
            variant="contained"
            color="secondary"
            onClick={() => setIsPreview((current) => !current)}
          >
            Preview
          </LoadingButton>
          <SaveButton
            {...(formLoading ? { disabled: true } : {})}
            {...saveButtonProps}
          />
        </>
      }
    >
      {files && (
        <ModalImageCrop
          imgFile={files[0]}
          isShow={isShow}
          onClose={onClose}
          onSubmit={onUploadBanner}
          option={{ aspect: 2 / 1, width: 600 }}
          title="Edit banner"
          titleCancel="CANCEL"
          titleSubmit="CONFIRM"
        />
      )}
      <Box
        component="form"
        display={isPreview ? "none" : "flex"}
        flexDirection={"column"}
        autoComplete="off"
      >
        <div className="mb-4">
          {watchBanner && typeof watchBanner === "string" && !files ? (
            <div className="inline-flex flex-col px-10 py-3 border border-solid">
              <div className="flex gap-3 justify-end">
                <Button variant="outlined" color="error" onClick={removeImg}>
                  ลบ
                </Button>
              </div>
              <img
                alt={""}
                className="w-[600px] h-[300px] mt-2"
                src={watchBanner}
                width="auto"
              />
            </div>
          ) : (
            <>
              {!croppedFile ? (
                <div
                  {...getRootProps()}
                  className="flex border border-dashed border-zinc-300 w-[350px] h-[250px] cursor-pointer justify-center bg-zinc-100"
                >
                  <input id={"banner_url"} {...getInputProps()} />
                  <div className="flex flex-col items-center justify-center h-full">
                    <BsImage size={40} />
                    <Typography className="pt-3">อัปโหลดรูปหน้าปก</Typography>
                    <Typography variant="body2">
                      รองรับเฉพาะไฟล์ PNG และ JPG
                    </Typography>
                  </div>
                </div>
              ) : (
                <div className="inline-flex flex-col px-10 py-3 border border-solid">
                  <div className="flex gap-3 justify-end">
                    <Button variant="outlined" onClick={onOpen}>
                      แก้ไข
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={removeImg}
                    >
                      ลบ
                    </Button>
                  </div>
                  <img
                    alt={croppedFile.name}
                    className="w-[600px] h-[300px] mt-2"
                    src={
                      typeof watchBanner === "string"
                        ? watchBanner
                        : URL.createObjectURL(croppedFile)
                    }
                    width="auto"
                  />
                </div>
              )}
            </>
          )}
          <div />
        </div>

        <TextField
          {...register("title", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          type="text"
          label="ชื่อหัวข้อ"
          InputLabelProps={{ shrink: true }}
          placeholder="หัวข้อ"
          name="title"
        />

        <TextField
          {...register("short_dsc", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.short_dsc}
          helperText={
            !!(errors as any)?.short_dsc
              ? (errors as any)?.short_dsc?.message
              : `${watchValues?.short_dsc?.length} / 120 ตัวอักษร`
          }
          inputProps={{ maxLength: 120 }}
          multiline
          margin="normal"
          fullWidth
          type="text"
          label="คำอธิบาย"
          InputLabelProps={{ shrink: true }}
          placeholder="คำอธิบาย"
          name="short_dsc"
        />

        <div className="flex my-4 gap-1 flex-col">
          <Typography variant="subtitle1" className="text-gray-500">
            เนื้อหา
          </Typography>
          <div className="border border-solid">
            <Editor
              editorState={contentState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                image: { uploadCallback: uploadImageCallBack },
                alt: { present: true, mandatory: true },
              }}
            />
          </div>
        </div>

        <div style={{ marginBottom: "80px" }}>
          <Typography variant="h6" style={{ marginTop: "16px" }}>
            ตั้งค่าการมองเห็น
          </Typography>
          <FormControlLabel
            control={<Switch defaultChecked {...register("is_public")} />}
            label="เผยแพร่เป็นสาธารณะเลย"
          />
        </div>
      </Box>

      {isPreview && (
        <BlogPreview
          banner_url={watchValues.banner_url}
          title={watchValues.title}
          short_dsc={watchValues.short_dsc}
          content={watchValues.content}
          is_public={watchValues.is_public}
        />
      )}
    </Create>
  );
};
