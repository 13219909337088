import * as Yup from "yup";
import {
  Controller,
  useFieldArray,
  useForm,
} from "@pankod/refine-react-hook-form";
import {
  Box,
  Button,
  Create,
  Edit,
  EditButton,
  MenuItem,
  Select,
  ShowButton,
  TextField,
  Typography,
} from "@pankod/refine-mui";
import { HttpError, useList, useShow } from "@pankod/refine-core";

import { useParams } from "@pankod/refine-react-router-v6";
import { useEffect, useMemo } from "react";
import { InvoiceAddressDataType } from "pages/users";

export interface ProfileEditDataType {
  first_name: string;
  sur_name: string;
  prefix: string;
  gender: string;
  phone_no: string;
  email: string;
  address: InvoiceAddressEditDataType[];
  occupation: string;
  company_name: string;
  user_id: string;
  profile_id: string;
  invoice_same_as_profile: boolean;
  primary_invoice: number | null;
  birth_date: string;
  personal_id: string;
  custom_occupation: string | null;
}

export interface InvoiceAddressEditDataType {
  invoice_address_id: number | null;
  address: string;
  name: string;
  invoice_number: string;
  phone_no: string;
  vat_license_image_url: string | null;
  type: "PERSONAL" | "COMPANY";
  user_id: number | null;
}

// export const schema: Yup.ObjectSchema<ProfileEditDataType> = Yup.object({
//   first_name: Yup.string().required(),
//   sur_name: Yup.string().required(),
//   prefix: Yup.string().required(),
//   gender: Yup.string().required(),
//   phone_no: Yup.string().required(),
//   email: Yup.string().required(),
//   address: Yup.object().shape(InvoiceAddressEditDataType),
//   address: Yup.string().required(),
//   occupation: Yup.string().required(),
//   company_name: Yup.string().required(),
// });

export const addressSchema: Yup.ObjectSchema<any> = Yup.object({
  address: Yup.string().required(),
  name: Yup.string().required(),
  invoice_number: Yup.string().required(),
  phone_no: Yup.string().required(),
  vat_license_image_url: Yup.string().required(),
});

export const ProfileCreate = () => {
  const paramString = useParams();
  // const { data, isLoading, isError } = useList<
  //   InvoiceAddressDataType,
  //   HttpError
  // >({
  //   resource: "invoice-addresses",
  // });
  // const invoiceAddresses = data?.data ?? [];
  const {
    saveButtonProps,
    refineCore: { formLoading },
    setValue,
    getValues,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<any, any, ProfileEditDataType>({
    mode: "onChange",
    // refineCoreProps: {
    //   action: "edit",
    //   resource: "profiles",
    //   //   id: paramString.id,
    // },
    // resolver: yupResolver(schema),
    defaultValues: {
      custom_occupation: null,
    },
  });

  const job = watch("occupation");

  useEffect(() => {
    if (paramString.id) {
      setValue("user_id", paramString.id);
    }
  }, [paramString, setValue]);

  useEffect(() => {
    if (job !== "ETC") {
      setValue("custom_occupation", null);
    }
  }, [job, setValue]);

  return (
    <Create
      title={
        <Typography variant="h5" color="textPrimary">
          สร้างโปรไฟล์
        </Typography>
      }
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      // headerButtons={
      //   <div>
      //     <Button>create</Button>
      //   </div>
      // }
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Typography variant="subtitle1">รายละเอียด</Typography>
        <TextField
          {...register("user_id")}
          disabled
          required
          error={!!(errors as any)?.user_id}
          helperText={(errors as any)?.user_id?.message}
          margin="normal"
          fullWidth
          type="text"
          label="user id"
          InputLabelProps={{ shrink: true }}
          name="user_id"
        />

        <TextField
          {...register("first_name", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.first_name}
          helperText={(errors as any)?.first_name?.message}
          margin="normal"
          fullWidth
          type="text"
          label="ชื่อ"
          InputLabelProps={{ shrink: true }}
          placeholder="ชื่อ"
          name="first_name"
        />
        <TextField
          {...register("sur_name", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.sur_name}
          helperText={(errors as any)?.sur_name?.message}
          margin="normal"
          fullWidth
          type="text"
          label="นามสกุล"
          InputLabelProps={{ shrink: true }}
          placeholder="นามสกุล"
          name="sur_name"
        />
        <div className="flex mt-2 gap-1 flex-col">
          <Typography variant="subtitle1" className="text-gray-500">
            คำนำหน้า
          </Typography>
          <Controller
            name="prefix"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                {...register("prefix")}
                onChange={(newValue) => onChange(newValue.target.value)}
              >
                <MenuItem value={"นาย"}>
                  <div>นาย</div>
                </MenuItem>
                <MenuItem value={"นาง"}>
                  <div>นาง</div>
                </MenuItem>
                <MenuItem value={"นางสาว"}>
                  <div>นางสาว</div>
                </MenuItem>
              </Select>
            )}
          />
        </div>
        <div className="flex mt-2 gap-1 flex-col">
          <Typography variant="subtitle1" className="text-gray-500">
            เพศ
          </Typography>
          <Controller
            name="gender"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                {...register("gender")}
                onChange={(newValue) => onChange(newValue.target.value)}
              >
                <MenuItem value={"ชาย"}>
                  <div>ชาย</div>
                </MenuItem>
                <MenuItem value={"หญิง"}>
                  <div>หญิง</div>
                </MenuItem>
                <MenuItem value={"LGBT"}>
                  <div>LGBT</div>
                </MenuItem>
              </Select>
            )}
          />
        </div>
        <TextField
          {...register("phone_no", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.phone_no}
          helperText={(errors as any)?.phone_no?.message}
          margin="normal"
          fullWidth
          type="text"
          label="เบอร์ติดต่อ"
          InputLabelProps={{ shrink: true }}
          placeholder="ใส่ข้อความที่นี่"
          name="phone_no"
        />

        <div className="py-1" />

        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          inputProps={{
            ...register(`birth_date` as const, {
              required: true,
            }),
          }}
          error={!!(errors as any)?.birth_date}
          helperText={(errors as any)?.birth_date?.message}
          type="date"
          label="วันเกิด"
          variant="outlined"
          name={`birth_date`}
        />

        <div className="py-1" />

        <TextField
          {...register("personal_id", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.personal_id}
          helperText={(errors as any)?.personal_id?.message}
          margin="normal"
          fullWidth
          type="text"
          label="เลขบัตรประชาชน"
          InputLabelProps={{ shrink: true }}
          placeholder="ใส่ข้อความที่นี่"
          name="personal_id"
        />

        <div className="py-1" />

        <TextField
          {...register("company_name", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.company_name}
          helperText={(errors as any)?.company_name?.message}
          margin="normal"
          fullWidth
          type="text"
          label="บริษัท"
          InputLabelProps={{ shrink: true }}
          placeholder="ใส่ข้อความที่นี่"
          name="company_name"
        />

        <TextField
          {...register("email", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.email}
          helperText={(errors as any)?.email?.message}
          margin="normal"
          fullWidth
          type="text"
          label="อีเมล"
          InputLabelProps={{ shrink: true }}
          placeholder="ใส่ข้อความที่นี่"
          name="email"
        />
        <TextField
          {...register("address", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.address}
          helperText={(errors as any)?.address?.message}
          margin="normal"
          fullWidth
          type="text"
          label="ที่อยู่"
          InputLabelProps={{ shrink: true }}
          placeholder="ใส่ข้อความที่นี่"
          name="address"
        />

        <div className="flex mt-2 gap-1 flex-col">
          <Typography variant="subtitle1" className="text-gray-500">
            อาชีพ
          </Typography>
          <Controller
            name="occupation"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                select
                label={watch("occupation") === "" ? "อาชีพ" : ""}
                value={value}
                onChange={(value) => onChange(value)}
                InputLabelProps={{ shrink: false }}
                required
              >
                <MenuItem value="STUDENT">นักเรียน / นักศึกษา</MenuItem>
                <MenuItem value="GOV_OFFICER">รับราชการ / ข้าราชการ</MenuItem>
                <MenuItem value="OFFICE_WORKER">พนักงานบริษัทเอกชน</MenuItem>
                <MenuItem value="ENGINEER">วิศวกร</MenuItem>
                <MenuItem value="ARCHITECT">สถาปนิก</MenuItem>
                <MenuItem value="BUSINESS_OWNER">
                  เจ้าของกิจการ / ธุรกิจส่วนตัว
                </MenuItem>
                <MenuItem value="FREELANCE">รับจ้างอิสระ / Freelance</MenuItem>
                <MenuItem value="ETC">อื่นๆ</MenuItem>
              </TextField>
            )}
          />
        </div>

        <div className="mt-4" />

        <TextField
          {...register("custom_occupation")}
          label="อาชีพที่กำหนดเอง"
          defaultValue={null}
        />

        <div className="flex mt-2 gap-1 flex-col">
          <Typography variant="subtitle1" className="text-gray-500">
            ใช้ที่อยู่เดียวกับโปรไฟล์
          </Typography>
          <Controller
            name="invoice_same_as_profile"
            control={control}
            defaultValue={true}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                {...register("invoice_same_as_profile")}
                onChange={(newValue) => {
                  onChange(newValue.target.value as any);
                  if (newValue.target.value) {
                    setValue("primary_invoice", null);
                  }
                }}
              >
                <MenuItem value={true as any}>
                  <div>ใช่</div>
                </MenuItem>
              </Select>
            )}
          />
        </div>

        <Typography>
          * admin จะสามารถเพิ่มที่อยู่สำหรับออกใบเสร็จได้หลังจากสร้าง profile
          ครั้งแรกสำเร็จ
        </Typography>
      </Box>
    </Create>
  );
};
