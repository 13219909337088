import { Show, Box, TextField, Typography } from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { useState, useMemo, useEffect } from "react";
import { AxiosInstance } from "axios";
import { axiosInstance } from "@pankod/refine-simple-rest";
import { REST_PUBLIC_URI } from "environment";
import { useShow } from "@pankod/refine-core";
import FormItem from "components/FormItem";

interface QueryDataType {
  enrollment_id: number;
  course_name: string;
  user_id: number;
  username: string;
  purchase_history_id: number;
  status: string;
  cancel_at: string;
  question: string[];
  course_infos: string[];
}
type Pair<T, U> = [T, U];

export const EnrollmentShow = () => {
  const { queryResult: data } = useShow<QueryDataType>();
  const inputData = data?.data?.data;

  const questionArray: String[] = useMemo(() => {
    if (inputData?.question) {
      return inputData.question;
    }
    return [];
  }, [inputData?.question]);
  const answerArray: String[] = useMemo(() => {
    if (inputData?.course_infos) {
      return inputData.course_infos;
    }
    return [];
  }, [inputData?.course_infos]);

  const courseInfos: Pair<String, String>[] = useMemo(() => {
    let data: Pair<String, String>[] = [];
    for (
      let i = 0;
      i < Math.max(questionArray?.length, answerArray?.length);
      i++
    ) {
      // Ensure you handle the case where the arrays might have different lengths
      let question = questionArray[i] || "";
      let answer = answerArray[i] || "";
      let pair: Pair<String, String> = [question, answer];
      data.push(pair);
    }
    return data;
  }, [answerArray, questionArray]);

  return (
    <Show canDelete={false} canEdit={false}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Typography
          variant="body2"
          className="inline-block mb-2 text-black"
          gutterBottom
        >
          Enrollment Id
          <div className="border rounded-md">
            <Typography variant="subtitle1" className="p-2" color="gray">
              {inputData?.enrollment_id}
            </Typography>
          </div>
        </Typography>

        <Typography
          variant="body2"
          className="inline-block mb-2 text-black"
          gutterBottom
        >
          Course Name
          <div className="border rounded-md">
            <Typography variant="subtitle1" className="p-2" color="gray">
              {inputData?.course_name || "ไม่มีชื่อคอร์ส"}
            </Typography>
          </div>
        </Typography>

        <Typography
          variant="body2"
          className="inline-block mb-2 text-black"
          gutterBottom
        >
          User Name
          <div className="border rounded-md">
            <Typography variant="subtitle1" className="p-2" color="gray">
              {inputData?.username || "ไม่มีชื่อผู้ใช้"}
            </Typography>
          </div>
        </Typography>

        <Typography
          variant="body2"
          className="inline-block mb-2 text-black"
          gutterBottom
        >
          Purchase History Id (RefId)
          <div className="border rounded-md">
            <Typography variant="subtitle1" className="p-2" color="gray">
              {inputData?.purchase_history_id || "ไม่มี RefId"}
            </Typography>
          </div>
        </Typography>

        <Typography
          variant="body2"
          className="inline-block mb-2 text-black"
          gutterBottom
        >
          Status
          <div className="border rounded-md">
            <Typography variant="subtitle1" className="p-2" color="gray">
              {inputData?.status || "ไม่มีสถานะ"}
            </Typography>
          </div>
        </Typography>

        <Typography
          variant="body2"
          className="inline-block mb-2 text-black"
          gutterBottom
        >
          Cancel At
          <div className="border rounded-md">
            <Typography variant="subtitle1" className="p-2" color="gray">
              {inputData?.cancel_at || "ยังไม่มีการยกเลิก"}
            </Typography>
          </div>
        </Typography>

        <FormItem className="mt-6" title="คำถามก่อนเข้าเรียน">
          <>
            {courseInfos.length > 0 ? (
              courseInfos.map((field, i) => (
                <div className="flex gap-4" key={i}>
                  <TextField
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label={field[0]}
                    name="course_infos"
                    value={field[1]}
                    disabled={true}
                    key={i + "a"}
                  />
                </div>
              ))
            ) : (
              <Typography variant="subtitle1" className="p-2" color="gray">
                ไม่มีคำถามก่อนเข้าเรียน
              </Typography>
            )}
          </>
        </FormItem>
      </Box>
    </Show>
  );
};
