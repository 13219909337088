import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  FieldErrors,
} from "react-hook-form";
import { FormDataCreate } from "./create";
import { Button } from "@pankod/refine-mui";
import FormEmailContainCard from "./FormEmailContainCard";

interface FormListProps {
  field: string[];
  control: Control<FormDataCreate>;
  register: UseFormRegister<FormDataCreate>;
  setValue: UseFormSetValue<FormDataCreate>;
  append: (arg: string) => void;
  move: (arg: number, arg2: number) => void;
  remove: (arg: number) => void;
  watchEmailContain: string[];
  errors: FieldErrors<FormDataCreate>;
  disabled?: boolean;
}

const FormEmailContainList = ({
  field,
  control,
  register,
  setValue,
  append,
  move,
  remove,
  watchEmailContain,
  errors,
  disabled,
}: FormListProps) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    move(result.source.index, result.destination.index);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {field.map((field, i) => {
                const emailContain = watchEmailContain[i];
                return (
                  <Draggable
                    draggableId={field + i}
                    index={i}
                    isDragDisabled={disabled}
                    key={i}
                  >
                    {(provided) => (
                      <FormEmailContainCard
                        control={control}
                        disabled={disabled}
                        emailContain={emailContain}
                        nestIndex={i}
                        register={register}
                        removeEmail={remove}
                        errors={errors}
                        setValue={setValue}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      />
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!disabled && (
        <div className="flex mt-2 gap-x-2">
          <Button onClick={() => append("")} type="button" variant="outlined">
            เพิ่มอีเมล
          </Button>
        </div>
      )}
    </>
  );
};

export default FormEmailContainList;
