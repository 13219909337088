import {
  Breakpoint,
  Button,
  Dialog,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

import { MdClose, MdArrowBackIos } from "react-icons/md";

interface Props {
  children?: JSX.Element;
  title?: string;
  subTitle?: string;
  text?: string;
  hideClose?: boolean;
  showBack?: boolean;
  onBack?: () => void;
  isShow: boolean;
  setIsShow: (res: boolean) => void;
  fullScreen?: boolean;
  maxWidth?: Breakpoint | false;
  sx?: SxProps<Theme> | undefined;
}

const Modal = ({
  title,
  subTitle,
  text,
  children,
  hideClose,
  showBack,
  onBack,
  isShow,
  setIsShow,
  fullScreen,
  maxWidth,
  sx,
}: Props) => {
  const showBackHideClose = () => {
    if (showBack === true) {
      return (
        <Button
          className="icon-close-24 absolute h-10 w-10 -left-1"
          onClick={onBack}
          title="Close"
        >
          <MdArrowBackIos fill="text-card-tx" height="32px" width="32px" />
        </Button>
      );
    }
    if (!hideClose === true) {
      return (
        <Button
          className="icon-close-24 absolute h-10 w-10 -left-3"
          onClick={() => setIsShow(false)}
          title="Close"
        >
          <MdClose height={16} width={16} />
        </Button>
      );
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      id="dialog"
      onClose={() => setIsShow(false)}
      open={isShow}
      scroll="body"
      sx={sx}
    >
      <div className="p-5 pt-6">
        <div className="pb-4 relative flex flex-col justify-center h-10 empty:h-0">
          {showBackHideClose()}
          {title && (
            <Typography className="text-center" variant="subtitle1">
              {title}
            </Typography>
          )}
        </div>
        {children}
        {(text || subTitle) && (
          <div className="p-4 text-center">
            <p>
              <small>{text}</small>
            </p>
            <p>{subTitle}</p>
          </div>
        )}
      </div>
    </Dialog>
  );
};
export default Modal;
