import { Button, Show, Typography } from "@pankod/refine-mui";
import { HttpError, useList, useShow } from "@pankod/refine-core";
import { BsPersonCircle } from "react-icons/bs";
import { useParams } from "@pankod/refine-react-router-v6";
import { useMemo } from "react";

export interface ProfileQueryDataType {
  profile_id: number;
  user_id: number;
  image_url: string;
  prefix: string;
  first_name: string;
  sur_name: string;
  gender: string;
  birth_date: string;
  personal_id: string;
  phone_no: string;
  email: string;
  address: string;
  occupation: string;
  custom_occupation: string;
  company_name: string;
  invoice_same_as_profile: boolean;
  primary_invoice: number;
  primary_invoice_address: string;
  created_at: string;
  updated_at: string;
}
export interface UserQueryDataType {
  user_id: number;
  profile_id: number;
  phone_no: string;
  email: string;
  name: string;
  role: string;
}

export interface InvoiceAddressDataType {
  invoice_address_id: number;
  user_id: number;
  type: string;
  name: string;
  invoice_number: string;
  address: string;
  phone_no: string;
  vat_license_image_url: string;
  created_at: string;
  updated_at: string;
}

export const UserShow = () => {
  const paramString = useParams();
  const { queryResult: profileResult } = useShow<ProfileQueryDataType>({
    resource: "profiles",
    id: paramString.id,
  });
  const { data: profileData, isLoading: profileLoding } = profileResult;
  const profile = profileData?.data;

  const { queryResult: userResult } = useShow<UserQueryDataType>({
    resource: "users",
    id: profile?.user_id,
  });
  const { data: userData, isLoading: userLoading } = userResult;
  const user = userData?.data;
  const { data, isLoading, isError } = useList<
    InvoiceAddressDataType,
    HttpError
  >({
    resource: "invoice-addresses",
  });
  const invoiceAddresses = data?.data;

  const invoiceAddresses_user = useMemo(() => {
    if (invoiceAddresses)
      return invoiceAddresses.filter(
        (invoiceAddress) => invoiceAddress.user_id === profile?.user_id
      );
  }, [profile?.user_id, invoiceAddresses]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong!</div>;
  }

  const openImagePreview = (imageUrl: string) => {
    const newTab = window.open("", "_blank");
    newTab?.document.write(`<img src="${imageUrl}" />`);
  };

  return (
    <Show
      isLoading={profileLoding}
      title={<Typography variant="h5">ข้อมูล</Typography>}
    >
      <div className="border border-solid shadow-md p-6">
        <Typography variant="h6">ข้อมูลส่วนตัว</Typography>
        <div className="mt-2 px-3 flex flex-col gap-4">
          <div className="grid grid-cols-5">
            <Typography className="font-normal text-sm">User ID</Typography>
            <Typography className="col-span-2">
              {profile?.user_id || "-"}
            </Typography>
          </div>
          <div className="flex gap-4 items-center">
            {profile?.image_url ? (
              <img
                alt={profile ? profile.sur_name : "-"}
                className="w-[40px] h-[40px] rounded-full"
                src={profile?.image_url}
              />
            ) : (
              <BsPersonCircle size={32} />
            )}
            <div>
              <Typography className="font-normal text-sm">
                account name
              </Typography>
              <Typography className="font-semibold text-base">
                {user?.name}
              </Typography>
            </div>
          </div>

          <div className="flex flex-col item-center justify-start">
            <Typography className="font-normal text-sm">ชื่อ</Typography>
            <div className="ml-1 mb-8">
              <Typography className="font-normal text-sm">
                {profile?.first_name || "-"}
              </Typography>
            </div>
            <Typography className="font-normal text-sm">นามสกุล</Typography>
            <div className="ml-1 mb-8">
              <Typography className="font-normal text-sm">
                {profile?.sur_name || "-"}
              </Typography>
            </div>
            <Typography className="font-normal text-sm">คำนำหน้า</Typography>
            <div className="ml-1 mb-8">
              <Typography className="font-normal text-sm">
                {profile?.prefix || "-"}
              </Typography>
            </div>
            <Typography className="font-normal text-sm">เพศ</Typography>
            <div className="ml-1 mb-8">
              <Typography className="font-normal text-sm">
                {profile?.gender || "-"}
              </Typography>
            </div>
            <Typography className="font-normal text-sm">เบอร์ติดต่อ</Typography>
            <div className="ml-1 mb-8">
              <Typography className="font-normal text-sm">
                {profile?.phone_no || "-"}
              </Typography>
            </div>
            <Typography className="font-normal text-sm">อีเมล</Typography>
            <div className="ml-1 mb-8">
              <Typography className="font-normal text-sm">
                {profile?.email || "-"}
              </Typography>
            </div>
            <Typography className="font-normal text-sm">ที่อยู่</Typography>
            <div className="ml-1 mb-8">
              <Typography className="font-normal text-sm">
                {profile?.address || "-"}
              </Typography>
            </div>
            <Typography className="font-normal text-sm">อาชีพ</Typography>
            <div className="ml-1 mb-8">
              <Typography className="font-normal text-sm">
                {profile?.occupation || "-"}
              </Typography>
            </div>
            <Typography className="font-normal text-sm">
              ชื่อบริษัท / สถาบัน
            </Typography>
            <div className="ml-1 mb-8">
              <Typography className="font-normal text-sm">
                {profile?.company_name || "-"}
              </Typography>
            </div>
            {invoiceAddresses_user &&
              invoiceAddresses_user.map((data, i) => (
                <div key={i} className="mb-4">
                  <Typography className="font-normal text-sm">
                    ที่อยู่ {i + 2} <br /> (สำหรับออกใบเสร็จ / กำกับภาษี)
                  </Typography>
                  <div className="ml-1 mb-8">
                    <Typography className="font-normal text-sm">
                      {data.address || "-"}
                    </Typography>
                  </div>
                  {data.vat_license_image_url && (
                    <Button
                      variant="contained"
                      className="mt-4"
                      onClick={() =>
                        openImagePreview(data.vat_license_image_url)
                      }
                    >
                      ใบภ.พ.20
                    </Button>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </Show>
  );
};
