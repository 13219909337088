import { MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  Control,
  useFieldArray,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  FieldErrors,
} from "react-hook-form";
import { MdDelete, MdAdd, MdClose } from "react-icons/md";
import { FormDataCreate, FormItemData, QuestionType } from "./create";
import { IconButton, Select } from "@pankod/refine-mui";
import { BsCircle } from "react-icons/bs";

const FormType: { type: QuestionType; description: string }[] = [
  {
    type: "RADIO",
    description: "เลือกคำตอบเดียว (Radio-Button)",
  },
  {
    type: "DROPDOWN",
    description: "เลือกคำตอบเดียว (Dropdown)",
  },
  {
    type: "TEXT",
    description: "ตอบแบบข้อความ (Text)",
  },
  {
    type: "SHORT_TEXT",
    description: "ตอบสั้นๆ (Short-Text)",
  },
];

type BaseDivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface FormCardProps extends BaseDivProps {
  nestIndex: number;
  control: Control<FormDataCreate>;
  register: UseFormRegister<FormDataCreate>;
  formItem: FormItemData;
  setValue: UseFormSetValue<FormDataCreate>;
  removeCard: UseFieldArrayRemove;
  errors: FieldErrors<FormDataCreate>;
  disabled?: boolean;
}

const FormCard = React.forwardRef<HTMLDivElement, FormCardProps>(
  (
    {
      nestIndex,
      control,
      register,
      formItem,
      setValue,
      removeCard,
      className,
      errors,
      disabled,
      ...rest
    },
    ref
  ) => {
    const { fields, replace } = useFieldArray({
      control,
      name: `formItem.info_choice` as any,
    });

    const handleAppend = () => {
      const choices = formItem.info_choice;
      if (choices) {
        const newChoice = [...choices, ""];
        return replace(newChoice);
      }
      return replace([""]);
    };
    const handleRemove = (index: number) => {
      const choices = formItem.info_choice;
      if (choices) {
        const newChoice = [...choices];
        newChoice.splice(index, 1);
        replace(newChoice);
        setValue(`course_infos.${nestIndex}.info_choice`, newChoice);
      }
    };

    const handleEmptyAppend = () => {
      const choices = formItem.info_choice;
      if (choices) {
        return replace(choices);
      }
      return;
    };

    const validateQuestion = (value: string) => {
      if (value === "") {
        return "Question is required";
      }
      const type = formItem.info_type;
      const choices = formItem.info_choice;
      if (type !== "TEXT" && type !== "SHORT_TEXT" && choices?.length <= 1) {
        return "Choice is required at least 2";
      }
      return true;
    };

    useEffect(() => {
      handleEmptyAppend();
      if (
        formItem.info_type === "TEXT" ||
        formItem.info_type === "SHORT_TEXT"
      ) {
        setValue(`course_infos.${nestIndex}.info_choice`, []);
      }
    }, [formItem.info_type]);

    return (
      <div
        className={`flex flex-col items-center w-full border p-4 gap-4 mb-3 `}
        ref={ref}
        {...rest}
      >
        <div className="w-full flex gap-2">
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            inputProps={{
              ...register(`course_infos.${nestIndex}.info_name` as const, {
                required: "Question is required",
                validate: validateQuestion,
              }),
            }}
            error={
              errors?.course_infos &&
              errors?.course_infos?.length &&
              errors?.course_infos?.length - 1 >= nestIndex &&
              !!errors?.course_infos[nestIndex]?.info_name
                ? true
                : false
            }
            helperText={
              errors?.course_infos &&
              errors?.course_infos?.length &&
              errors?.course_infos?.length - 1 >= nestIndex &&
              errors?.course_infos[`${nestIndex}`]?.info_name
                ? errors?.course_infos[`${nestIndex}`]?.info_name?.message
                : ""
            }
            label="คำถาม"
            type="text"
            variant="filled"
          />
          <Select
            disabled={disabled}
            value={formItem.info_type}
            fullWidth
            {...register(`course_infos.${nestIndex}.info_type` as const, {})}
          >
            {FormType.map((item) => (
              <MenuItem key={item.type} value={item.type}>
                <div>{item.description}</div>
              </MenuItem>
            ))}
          </Select>
        </div>

        {formItem.info_type !== "TEXT" &&
          formItem.info_type !== "SHORT_TEXT" && (
            <div className="w-full">
              <ul className="p-0 space-y-2 max-h-60 overflow-y-scroll">
                {fields.map((field, i) => {
                  return (
                    <li className="flex items-center pr-2" key={field.id}>
                      {formItem.info_type === "DROPDOWN" && (
                        <Typography variant="subtitle2">{i + 1}.</Typography>
                      )}
                      {formItem.info_type === "RADIO" && <BsCircle />}
                      <div className="px-2">
                        <TextField
                          inputProps={{
                            ...register(
                              `course_infos.${nestIndex}.info_choice.${i}` as const,
                              {
                                required: "Choice is required",
                                validate: (value) => value !== "",
                              }
                            ),
                          }}
                          className="w-full h-full py-8 px-3 text-gray-700 leading-tight bg-transparent focus:outline-none"
                          disabled={disabled}
                          variant="standard"
                          margin="none"
                          error={
                            errors?.course_infos &&
                            !!errors.course_infos[nestIndex]?.info_choice &&
                            !!errors.course_infos[nestIndex]?.info_choice?.[i]
                              ? true
                              : false
                          }
                          helperText={
                            errors?.course_infos &&
                            !!errors.course_infos[nestIndex]?.info_choice &&
                            !!errors.course_infos[nestIndex]?.info_choice?.[i]
                              ? errors.course_infos[nestIndex]?.info_choice?.[i]
                                  ?.message
                              : ""
                          }
                          value={formItem.info_choice[i]}
                        />
                      </div>
                      {!disabled && fields?.length > 2 && (
                        <button
                          className="bg-transparent"
                          onClick={() => handleRemove(i)}
                          type="button"
                        >
                          <MdClose height="16px" width="16px" />
                        </button>
                      )}
                    </li>
                  );
                })}
              </ul>
              {!disabled && (
                <button
                  className="bg-transparent flex pt-3 items-center gap-2"
                  onClick={handleAppend}
                  type="button"
                >
                  <MdAdd height="12px" width="12px" />

                  <Typography
                    className="ml-2"
                    color="text-T010"
                    variant="subtitle2"
                  >
                    เพิ่มตัวเลือก
                  </Typography>
                </button>
              )}
            </div>
          )}
        {!disabled && (
          <>
            <hr className=" w-full" />
            <div className="flex w-full justify-end">
              <IconButton
                className="p-2 self-end"
                onClick={() => removeCard(nestIndex)}
              >
                <MdDelete className="text-lg" size={24} />
              </IconButton>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default FormCard;
