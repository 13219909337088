import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  EditButton,
  List,
  DeleteButton,
} from "@pankod/refine-mui";

export const CourseDiscountList = () => {
  const { dataGridProps } = useDataGrid({
    queryOptions: {
      retry: 1,
    },
  });

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "actions",
        headerName: "Action",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.course_discount_id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
      },
      {
        field: "course_discount_id",
        headerName: "Course Discount Id",
        minWidth: 200,
        type: "number",
        valueFormatter: ({ value }) =>
          value.toLocaleString("en-US", { useGrouping: false }),
        headerAlign: "left",
        align: "left",
      },
      {
        field: "course_name",
        headerName: "Course Name",
        minWidth: 200,
        type: "string",
        headerAlign: "left",
        align: "left",
      },
      {
        field: "email_contain",
        headerName: "Email Contain",
        minWidth: 400,
        valueFormatter: ({ value }) =>
          Array.isArray(value) ? value.join(", ") : value,
        headerAlign: "left",
        align: "left",
        sortable: false,
      },
      {
        field: "amount",
        headerName: "Amount",
        minWidth: 100,
        headerAlign: "left",
        align: "left",
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        getRowId={(row) => row.course_id}
        columns={columns}
        autoHeight
        disableColumnFilter={true}
      />
    </List>
  );
};
