import { differenceInMinutes, format } from "date-fns";
import { th } from "date-fns/locale";

export interface CourseTime {
  start_at?: string;
  end_at?: string;
}

const days = ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัส", "ศุกร์", "เสาร์"];
const months = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
];

export function getHourAndMinFromTime(timestamp: string) {
  const currentDate = new Date(timestamp);
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

export function renderCourseTime(course_time: CourseTime) {
  if (!course_time.start_at || !course_time.end_at) return "-";
  const startDate = new Date(course_time.start_at);
  const endDate = new Date(course_time.end_at);
  const weekday = [
    "อาทิตย์",
    "จันทร์",
    "อังคาร",
    "พุธ",
    "พฤหัสบดี",
    "ศุกร์",
    "เสาร์",
  ];

  const isSameDay =
    course_time.start_at.slice(0, 10) === course_time.end_at.slice(0, 10);

  return `${weekday[startDate.getDay()]} ${startDate.getDate()} ${
    months[startDate.getMonth()]
  } ${startDate.getFullYear()} | ${getHourAndMinFromTime(
    course_time.start_at
  )} - ${
    isSameDay
      ? ""
      : `${days[endDate.getDay()]} ${endDate.getDate()} ${
          months[endDate.getMonth()]
        }  ${endDate.getFullYear()} | `
  } ${getHourAndMinFromTime(course_time.end_at)}`;
}

export function getFormatDifferenceTime(start_at: string, end_at: string) {
  const date1 = new Date(start_at);
  const date2 = new Date(end_at);

  const diff_as_date = new Date(date2.getTime() - date1.getTime());

  const timeDiff = Math.abs(date2.getTime() - date1.getTime());

  const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hourDiff = Math.floor(timeDiff / (1000 * 60 * 60)) % 24;
  const minDiff = diff_as_date.getMinutes();

  return `${dayDiff === 0 ? "" : `${dayDiff} วัน`} ${
    hourDiff === 0 ? "" : `${hourDiff} ชั่วโมง`
  } ${minDiff === 0 ? "" : `${minDiff} นาที`}`;
}

export function formatDateTime24Hr(date: string) {
  return format(new Date(date), "dd LLL yyyy HH:mm", { locale: th });
}

export function formatYearMonthDay(date: string | Date) {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function totalCourseDuration(courseTimes: CourseTime[]) {
  const totalDurationInMinutes = courseTimes.reduce((total, courseTime) => {
    if (!courseTime.start_at || !courseTime.end_at) return total;

    const startAt = new Date(courseTime.start_at);
    const endAt = new Date(courseTime.end_at);

    return total + differenceInMinutes(endAt, startAt);
  }, 0);

  if (totalDurationInMinutes === 0) {
    return "-";
  }

  const days = Math.floor(totalDurationInMinutes / (24 * 60));
  const hours = Math.floor((totalDurationInMinutes % (24 * 60)) / 60);
  const minutes = totalDurationInMinutes % 60;

  const formattedDuration = [
    days > 0 ? `${days} วัน` : "",
    hours > 0 ? `${hours} ชั่วโมง` : "",
    minutes > 0 ? `${minutes} นาที` : "",
  ].join(" ").trim();

  return formattedDuration;
}
