import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  Control,
  UseFieldArrayAppend,
  UseFieldArrayMove,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  FieldErrors,
} from "react-hook-form";
import { FormDataCreate } from "./create";
import { Button } from "@pankod/refine-mui";
import FormTimeCard from "./FormTimeCard";
import { useWatch } from "@pankod/refine-react-hook-form";

interface FormListProps {
  control: Control<FormDataCreate>;
  register: UseFormRegister<FormDataCreate>;
  setValue: UseFormSetValue<FormDataCreate>;
  move: UseFieldArrayMove;
  append: UseFieldArrayAppend<FormDataCreate, "course_times">;
  remove: UseFieldArrayRemove;
  watch: UseFormWatch<FormDataCreate>;
  errors: FieldErrors<FormDataCreate>;
  disabled?: boolean;
}

const FormTimeList = ({
  control,
  register,
  setValue,
  move,
  append,
  remove,
  watch,
  errors,
  disabled,
}: FormListProps) => {
  const courseTimes = useWatch({control, name: "course_times"})

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    move(result.source.index, result.destination.index);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {courseTimes.map((courseTime, i) => {
                return (
                  <FormTimeCard
                    control={control}
                    disabled={disabled}
                    courseTimes={courseTime}
                    nestIndex={i}
                    register={register}
                    removeTime={remove}
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                    key={"draggable"+i}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!disabled && (
        <div className="flex mt-2 gap-x-2">
          <Button
            onClick={() =>
              append({
                start_at: "",
                end_at: "",
              })
            }
            type="button"
            variant="outlined"
            // disabled={watchCourseTimes.length > 0}
          >
            เพิ่มเวลาเรียน
          </Button>
        </div>
      )}
    </>
  );
};

export default FormTimeList;
