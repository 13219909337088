import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  EditButton,
  List,
  DeleteButton,
} from "@pankod/refine-mui";
import { formatDateTime24Hr } from "utils/util";

export const NewsList = () => {
  const { dataGridProps } = useDataGrid({
    queryOptions: {
      retry: 1,
    },
  });

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "actions",
        headerName: "Action",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.news_id} />
              <DeleteButton hideText recordItemId={row.news_id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
      },
      {
        field: "created_at",
        headerName: "วันที่สร้าง",
        minWidth: 200,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          const createdAt = params.value as string;
          return createdAt ? formatDateTime24Hr(createdAt) : "-";
        },
      },
      {
        field: "title",
        headerName: "หัวข้อ",
        minWidth: 350,
        type: "text",
        headerAlign: "left",
        align: "left",
      },
      {
        field: "is_public",
        headerName: "สถานะ",
        minWidth: 100,
        headerAlign: "left",
        align: "left",
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        getRowId={(row) => row.news_id}
        columns={columns}
        autoHeight
        disableColumnFilter={true}
      />
    </List>
  );
};
