import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { AxiosInstance } from "axios";
import { axiosInstance } from "@pankod/refine-simple-rest";
import { REST_PUBLIC_URI } from "environment";
import {
  Box,
  Button,
  Edit,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@pankod/refine-mui";
import useModalState from "hooks/useModalState";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ModalImageCrop from "components/Modals/ModalImageCrop";
import { BsImage } from "react-icons/bs";
import { FormDataCreateLecturer } from "./create";
import { LecturerQueryDataType } from "./show";
import { useShow } from "@pankod/refine-core";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

interface data {
  file_url: string;
}

interface fileUpload {
  data: data;
}

export const LecturerEdit = () => {
  const { isShow, onClose, onOpen } = useModalState();
  const { queryResult } = useShow<LecturerQueryDataType>();
  const { data, isLoading } = queryResult;
  const lecturer = data?.data;
  const [contentState, setContentState] = useState(EditorState.createEmpty());
  const [positionContentState, setPositionContentState] = useState(
    EditorState.createEmpty()
  );

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm<FormDataCreateLecturer>({
    mode: "onChange",
  });

  const [files, setFiles] = useState<File[]>();
  const [croppedFile, setCroppedFile] = useState<File>();
  const onDrop = async (droppedFiles: File[]) => {
    setFiles(droppedFiles);
    onOpen();
  };
  const upload = async (base64: File) => {
    const x = new FormData();
    x.append("file", base64);
    const httpClient: AxiosInstance = axiosInstance;
    const url = `${REST_PUBLIC_URI}/api/v1/admin/files/upload_file`;
    const data: fileUpload = await httpClient.post(url, x);
    return data;
  };

  const onUploadBanner = async (file: File) => {
    const file_url = await upload(file);
    setValue("image_url", file_url?.data?.file_url);
    setCroppedFile(file);
    onClose();
  };
  const watchBanner = watch("image_url");

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
  });
  const removeImg = () => {
    setFiles(undefined);
    setCroppedFile(undefined);
    setValue("image_url", undefined);
  };

  const uploadImageCallBack = (file: File, callback: any) => {
    return new Promise((resolve) => {
      const reader = new window.FileReader();
      reader.onloadend = async () => {
        const res = await upload(file);
        resolve({ data: { link: res.data.file_url } });
      };
      reader.readAsDataURL(file);
    });
  };

  const onEditorStateChange = function (contentState: any) {
    setContentState(contentState);
    const raw = convertToRaw(contentState.getCurrentContent());
    setValue("short_bio", JSON.stringify(raw));
  };

  const onPositionEditorStateChange = function (contentState: any) {
    setPositionContentState(contentState);
    const raw = convertToRaw(contentState.getCurrentContent());
    setValue("position", JSON.stringify(raw));
  };

  useEffect(() => {
    if (lecturer?.image_url) setValue("image_url", lecturer?.image_url);
    if (!isLoading && lecturer?.is_public) setValue("is_public", true);
    if (lecturer?.short_bio) {
      try {
        const bioJson = JSON.parse(lecturer.short_bio);
        const formatted = convertFromRaw(bioJson);
        setContentState(EditorState.createWithContent(formatted));
        setValue("short_bio", lecturer.short_bio);
      } catch (e) {
        setContentState(EditorState.createEmpty());
        setValue(
          "short_bio",
          JSON.stringify(EditorState.createEmpty().getCurrentContent())
        );
      }
    }
    if (lecturer?.position) {
      try {
        const bioJson = JSON.parse(lecturer.position);
        const formatted = convertFromRaw(bioJson);
        setPositionContentState(EditorState.createWithContent(formatted));
        setValue("position", lecturer.position);
      } catch (e) {
        setPositionContentState(EditorState.createEmpty());
        setValue(
          "position",
          JSON.stringify(EditorState.createEmpty().getCurrentContent())
        );
      }
    }
  }, [
    lecturer?.image_url,
    lecturer?.is_public,
    lecturer?.short_bio,
    lecturer?.position,
    isLoading,
    setValue,
  ]);

  return (
    <Edit
      title={
        <Typography variant="h5" color="textPrimary">
          แก้ไขวิทยากร
        </Typography>
      }
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
    >
      {files && (
        <ModalImageCrop
          imgFile={files[0]}
          isShow={isShow}
          onClose={onClose}
          onSubmit={onUploadBanner}
          option={{ aspect: 1.0, width: 600 }}
          title="Edit banner"
          titleCancel="CANCEL"
          titleSubmit="CONFIRM"
        />
      )}
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Typography variant="subtitle1">รายละเอียด</Typography>

        <div>
          {watchBanner && typeof watchBanner === "string" && !files ? (
            <>
              <div className="inline-flex flex-col px-10 py-3 border border-solid">
                <div className="flex gap-3 justify-end">
                  <Button variant="outlined" color="error" onClick={removeImg}>
                    ลบ
                  </Button>
                </div>
                <img
                  alt={""}
                  className="w-[300px] h-[300px] mt-2"
                  src={watchBanner}
                  width="auto"
                />
              </div>
            </>
          ) : (
            <>
              {!croppedFile ? (
                <div
                  {...getRootProps()}
                  className="flex border border-dashed border-zinc-300 w-[350px] h-[250px] cursor-pointer justify-center bg-zinc-100"
                >
                  <input id={"image_url"} {...getInputProps()} />
                  <div className="flex flex-col items-center justify-center h-full">
                    <BsImage size={40} />
                    <Typography className="pt-3">อัปโหลดรูป</Typography>
                    <Typography variant="body2">
                      รองรับเฉพาะไฟล์ PNG และ JPG
                    </Typography>
                  </div>
                </div>
              ) : (
                <div className="inline-flex flex-col px-10 py-3 border border-solid">
                  <div className="flex gap-3 justify-end">
                    <Button variant="outlined" onClick={onOpen}>
                      แก้ไข
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={removeImg}
                    >
                      ลบ
                    </Button>
                  </div>
                  <img
                    alt={croppedFile.name}
                    className="w-[300px] h-[300px] mt-2"
                    src={
                      typeof watchBanner === "string"
                        ? watchBanner
                        : URL.createObjectURL(croppedFile)
                    }
                    width="auto"
                  />
                </div>
              )}
            </>
          )}
          <div />
        </div>
        <TextField
          {...register("prefix")}
          margin="normal"
          fullWidth
          type="text"
          label="คำนำหน้า"
          InputLabelProps={{ shrink: true }}
          placeholder="คำนำหน้า"
          name="prefix"
        />

        <TextField
          {...register("surname", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.surname}
          helperText={(errors as any)?.surname?.message}
          margin="normal"
          fullWidth
          type="text"
          label="ชื่อ"
          InputLabelProps={{ shrink: true }}
          placeholder="ชื่อ"
          name="surname"
        />

        <TextField
          {...register("lastname", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.lastname}
          helperText={(errors as any)?.lastname?.message}
          margin="normal"
          fullWidth
          type="text"
          label="นามสกุล"
          InputLabelProps={{ shrink: true }}
          placeholder="นามสกุล"
          name="lastname"
        />

        <div className="flex my-4 gap-1 flex-col">
          <Typography variant="subtitle1" className="text-gray-500">
            ตำแหน่ง
          </Typography>
          <div className="border border-solid">
            <Editor
              editorState={positionContentState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onPositionEditorStateChange}
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                image: { uploadCallback: uploadImageCallBack },
                alt: { present: true, mandatory: true },
              }}
            />
          </div>
        </div>

        <div className="flex my-4 gap-1 flex-col">
          <Typography variant="subtitle1" className="text-gray-500">
            ประวัติโดยย่อ
          </Typography>
          <div className="border border-solid">
            <Editor
              editorState={contentState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                image: { uploadCallback: uploadImageCallBack },
                alt: { present: true, mandatory: true },
              }}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6" style={{ marginTop: "16px" }}>
            ตั้งค่าการมองเห็น
          </Typography>

          <Controller
            name="is_public"
            control={control}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                control={
                  <Switch
                    {...register("is_public")}
                    checked={value}
                    onChange={(value) => onChange(value)}
                  />
                }
                label="เผยแพร่เป็นสาธารณะเลย"
              />
            )}
          />
        </div>
      </Box>
    </Edit>
  );
};
