import { Edit, Box, TextField, Typography, Select } from "@pankod/refine-mui";
import {
  Autocomplete,
  Button,
  Chip,
  FormControlLabel,
  MenuItem,
  Switch,
} from "@mui/material";
import {
  Controller,
  useFieldArray,
  useForm,
} from "@pankod/refine-react-hook-form";

import { FormDataCreate } from "./create";
import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { AxiosInstance } from "axios";
import { axiosInstance } from "@pankod/refine-simple-rest";
import { REST_PUBLIC_URI } from "environment";
import FormItem from "components/FormItem";
import FormList from "./FormList";
import FormTimeList from "./FormTimeList";
import FormEmailContainList from "./FormEmailContainList";
import { FormIsShowType } from "./create";
import { LecturerQueryDataType } from "pages/lecturer";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import * as htmlToImage from "html-to-image";
import { Link } from "react-router-dom";
import useModalState from "hooks/useModalState";
import { useDropzone } from "react-dropzone";
import { BsImage } from "react-icons/bs";
import ModalImageCrop from "components/Modals/ModalImageCrop";
import CertificatePreview from "components/CertificatePreview";
interface data {
  file_url: string;
}

interface fileUpload {
  data: data;
}

interface LecturersQueryType {
  items: LecturerQueryDataType[];
}

export const CourseEdit = () => {
  const {
    saveButtonProps,
    register,
    watch,
    setValue,
    getValues,
    control,
    refineCore: { queryResult },
    formState: { errors },
  } = useForm<any, any, FormDataCreate>({
    defaultValues: {
      course_infos: [],
      course_times: [{ start_at: undefined, end_at: undefined }],
      course_discount: {
        email_contain: [],
      },
      is_show: true,
    },
  });

  const {
    remove: removeCard,
    move: moveCard,
    append: appendCard,
  } = useFieldArray({
    control,
    name: "course_infos",
    rules: {},
  });

  const {
    remove: removeTime,
    move: moveTime,
    append: appendTime,
  } = useFieldArray({
    control,
    name: "course_times",
    rules: { required: true },
  });
  const data_course = queryResult?.data?.data;
  const { isShow, onClose, onOpen } = useModalState();
  const [files, setFiles] = useState<File[]>();
  const [croppedFile, setCroppedFile] = useState<File>();
  const descRef = useRef<HTMLDivElement>(null);
  const [contentState, setContentState] = useState(EditorState.createEmpty());
  const [lecturers, setLecturers] = useState<LecturersQueryType>();
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>("Only image is allowed");
  const emailContains = watch("course_discount.email_contain");
  const allowedExtension = ["image/jpeg", "image/jpg", "image/png"];
  const watchValues = watch();
  const watchBanner = watch("banner_url");
  const [isCertificate, setIsCertificate] = useState<boolean>(false);
  const [certificateUrl, setCertificateUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const getLecturers = useCallback(async () => {
    const httpClient: AxiosInstance = axiosInstance;
    const url = `${REST_PUBLIC_URI}/api/v1/admin/lecturers`;
    const data = await httpClient.get(url);
    setLecturers(data?.data);
  }, []);

  const onDrop = async (droppedFiles: File[]) => {
    setFiles(droppedFiles);
    onOpen();
  };
  const upload = async (base64: File) => {
    const x = new FormData();
    x.append("file", base64);
    const httpClient: AxiosInstance = axiosInstance;
    const url = `${REST_PUBLIC_URI}/api/v1/admin/files/upload_file`;
    const data: fileUpload = await httpClient.post(url, x);
    return data;
  };

  const onUploadBanner = async (file: File) => {
    try {
      const file_url = await upload(file);
      setValue("banner_url", file_url?.data?.file_url);
      setCroppedFile(file);
      onClose();
    } catch (error) {
      setHelperText(
        error && (error as Error).message
          ? (error as Error).message
          : "Error occurred, please try again"
      );
      setError(true);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
  });
  const removeImg = () => {
    setFiles(undefined);
    setCroppedFile(undefined);
    setValue("banner_url", "");
  };

  const renderChoiceformat = () => {
    if (!queryResult?.data) {
      return null;
    }
    return (
      <FormItem className="" title="ตั้งค่าคำถาม">
        <FormList
          append={appendCard}
          control={control}
          move={moveCard}
          register={register}
          remove={removeCard}
          setValue={setValue}
          errors={errors}
        />
      </FormItem>
    );
  };

  const renderCousesTime = () => {
    if (!queryResult?.data) {
      return null;
    }
    return (
      <FormItem className="" title="ตารางเรียน">
        <FormTimeList
          append={appendTime}
          control={control}
          move={moveTime}
          register={register}
          remove={removeTime}
          setValue={setValue}
          watch={watch}
          errors={errors}
        />
      </FormItem>
    );
  };

  const appendEmailContain = (value: string) => {
    const emailContains = getValues("course_discount.email_contain") || [];
    if (emailContains === null) {
      setValue("course_discount.email_contain", [value]);
      return;
    }
    setValue("course_discount.email_contain", [...emailContains, value]);
  };

  const removeEmailContain = (index: number) => {
    const emailContains = getValues("course_discount.email_contain") || [];
    setValue(
      "course_discount.email_contain",
      emailContains.filter((_, i) => i !== index)
    );
  };

  const moveEmailContain = (from: number, to: number) => {
    const emailContains = getValues("course_discount.email_contain") || [];
    const emailContainCopy = [...emailContains];
    const [removed] = emailContainCopy.splice(from, 1);
    emailContainCopy.splice(to, 0, removed);
    setValue("course_discount.email_contain", emailContainCopy);
  };

  const rawContent = convertToRaw(contentState.getCurrentContent());

  const onEditorStateChange = function (contentState: any) {
    setContentState(contentState);
    const rawContent = convertToRaw(contentState.getCurrentContent());
    setValue("certificate_template", [rawContent]);
  };

  const downloadImage = async () => {
    if (descRef !== null) {
      const dataUrl = await htmlToImage.toPng(descRef?.current as any);
      setValue("certificate_template_image_url", dataUrl);
    }
  };

  const textContent = useMemo(() => {
    if (watchValues.certificate_template)
      return draftToHtml(watchValues.certificate_template[0] as any);
  }, [watchValues.certificate_template]);

  useEffect(() => {
    if (rawContent.blocks[0].text !== "") {
      downloadImage();
    }
  }, [rawContent]);

  useEffect(() => {
    getLecturers();
  }, [getLecturers]);

  useEffect(() => {
    if (data_course?.certificate_template) {
      const formatted = convertFromRaw(
        data_course?.certificate_template[0] as any
      );
      setContentState(EditorState.createWithContent(formatted));
      setValue("certificate_template", [data_course?.certificate_template[0]]);
    }
    if (data_course?.banner_url)
      setValue("banner_url", data_course?.banner_url);

    if (data_course?.duration) setValue("hours", data_course?.duration[1]);
  }, [
    setValue,
    data_course?.certificate_template,
    data_course?.banner_url,
    data_course?.duration,
  ]);

  useEffect(() => {
    if (!watchValues.enabled_certificate) {
      setValue("certificate_template", [rawContent]);
      setValue("certificate_template_image_url", "");
      setContentState(EditorState.createEmpty());
    }
  }, [watchValues.enabled_certificate, setValue]);

  const handlePreview = () => {
    setCertificateUrl(watchValues.certificate_template_image_url);
    setLoading(true);
    setIsCertificate(true);
  };

  useEffect(() => {
    if (watchValues.hours) {
      setValue("duration", [0, watchValues.hours, 0]);
    }
  }, [watchValues.hours, setValue]);

  return (
    <>
      <Box display={isCertificate ? "none" : "flex"} flexDirection={"column"}>
        <Edit
          title={
            <Typography variant="h5" color="textPrimary">
              แก้ไขหลักสูตร
            </Typography>
          }
          canDelete={false}
          saveButtonProps={saveButtonProps}
        >
          {files && (
            <ModalImageCrop
              imgFile={files[0]}
              isShow={isShow}
              onClose={onClose}
              onSubmit={onUploadBanner}
              option={{ aspect: 2 / 1, width: 600 }}
              title="Edit banner"
              titleCancel="CANCEL"
              titleSubmit="CONFIRM"
            />
          )}
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column" }}
            autoComplete="off"
          >
            <div>
              {watchBanner && typeof watchBanner === "string" && !files ? (
                <div className="inline-flex flex-col px-10 py-3 border border-solid">
                  <div className="flex gap-3 justify-end">
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={removeImg}
                    >
                      ลบ
                    </Button>
                  </div>
                  <img
                    alt={""}
                    className="w-[600px] h-[300px] mt-2"
                    src={watchBanner}
                    width="auto"
                  />
                </div>
              ) : (
                <>
                  {!croppedFile ? (
                    <div
                      {...getRootProps()}
                      className="flex border border-dashed border-zinc-300 w-[350px] h-[250px] cursor-pointer justify-center bg-zinc-100"
                    >
                      <input id={"image_url"} {...getInputProps()} />
                      <div className="flex flex-col items-center justify-center h-full">
                        <BsImage size={40} />
                        <Typography className="pt-3">อัปโหลดรูป</Typography>
                        <Typography variant="body2">
                          รองรับเฉพาะไฟล์ PNG และ JPG
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <div className="inline-flex flex-col px-10 py-3 border border-solid">
                      <div className="flex gap-3 justify-end">
                        <Button variant="outlined" onClick={onOpen}>
                          แก้ไข
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={removeImg}
                        >
                          ลบ
                        </Button>
                      </div>
                      <img
                        alt={croppedFile.name}
                        className="w-[600px] h-[300px] mt-2"
                        src={
                          typeof watchBanner === "string"
                            ? watchBanner
                            : URL.createObjectURL(croppedFile)
                        }
                        width="auto"
                      />
                    </div>
                  )}
                </>
              )}
              <div />
            </div>
            <p className="text-xs mt-1 mb-4">* ขนาดรูปภาพ 1200x600 pixels</p>
            <TextField
              {...register("name", {
                required: "This field is required",
              })}
              required
              error={!!(errors as any)?.name}
              helperText={(errors as any)?.name?.message}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="ชื่อหลักสูตร"
              name="name"
            />

            <div className="flex mt-2 gap-1 flex-col">
              <Typography variant="subtitle1" className="text-gray-500">
                วิทยากร
              </Typography>
              <Controller
                name="lecturer_id"
                control={control}
                defaultValue={0}
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="โปรดเลือกวิทยากร"
                    value={value}
                    onChange={(newValue) => {
                      if (newValue.target.value === "") {
                        return onChange(null as unknown as number);
                      }
                      onChange(newValue.target.value as number);
                    }}
                  >
                    <MenuItem value="">
                      <div>ไม่ปรากฏวิทยากร</div>
                    </MenuItem>
                    {lecturers?.items.map((lecturer, i) => {
                      return (
                        <MenuItem key={i} value={lecturer.lecturer_id}>
                          <div>{`${lecturer.surname} ${lecturer.lastname} ${
                            lecturer.is_public ? "" : "(ไม่เผยแพร่เป็นสาธารณะ)"
                          }`}</div>
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
            </div>

            <div className="py-6">
              {renderCousesTime()}
              {errors.course_times?.root?.type && (
                <div className="text-[#fa541c] text-sm mt-1">
                  *เวลาเรียน {errors.course_times?.root?.type}
                </div>
              )}
            </div>

            <TextField
              {...register("hours", {
                setValueAs: (value) => Number(value),
                min: {
                  value: 0,
                  message: "hours must be greater than or equal to 0",
                },
              })}
              error={!!(errors as any)?.hours}
              helperText={(errors as any)?.hours?.message}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="number"
              label="จำนวนชั่วโมงเรียน"
              placeholder="0"
              name="hours"
            />

            <TextField
              {...register("tag_line", {
                required: "This field is required",
              })}
              required
              error={!!(errors as any)?.tag_line}
              helperText={(errors as any)?.tag_line?.message}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="คำโปรย"
              name="tag_line"
            />

            <TextField
              {...register("description", {
                required: "This field is required",
              })}
              required
              error={!!(errors as any)?.description}
              helperText={(errors as any)?.description?.message}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              multiline
              maxRows={5}
              minRows={5}
              label="คำอธิบาย"
              name="description"
            />

            <div className="flex gap-4">
              <TextField
                inputProps={{
                  ...register("start_at", {
                    required: "This field is required",
                  }),
                }}
                error={!!(errors as any)?.start_at}
                helperText={(errors as any)?.start_at?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
                label="ลงทะเบียนเรียนหลักสูตรนี้ได้ตั้งแต่"
                name="start_at"
                // value={new Date(watch("start_at"))}
              />
              <TextField
                inputProps={{
                  ...register("close_at", {
                    required: "This field is required",
                  }),
                }}
                error={!!(errors as any)?.close_at}
                helperText={(errors as any)?.close_at?.message}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
                label="ถึง"
                name="close_at"
                // value={new Date(watch("close_at"))}
              />
            </div>

            <TextField
              {...register("location", {
                required: "This field is required",
              })}
              required
              error={!!(errors as any)?.location}
              helperText={(errors as any)?.location?.message}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="text"
              label="สถานที่เรียน"
              name="location"
            />

            <TextField
              {...register("price", {
                required: "This field is required",
                min: {
                  value: 0,
                  message: "Price must be greater than or equal to 0",
                },
              })}
              required
              error={!!(errors as any)?.price}
              helperText={(errors as any)?.price?.message}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="number"
              label="ราคาหลักสูตร (บาท)"
              name="price"
            />

            <div className="py-6">{renderChoiceformat()}</div>

            <div className="py-6">
              <div>
                <Typography variant="subtitle1" className="text-gray-500">
                  ระบุ email ที่ต้องการให้ได้รับส่วนลด (ถ้ามี)
                </Typography>
                {errors?.course_discount?.email_contain && (
                  <Typography variant="caption" color="#fa541c">
                    โปรดระบุอย่างน้อย 1 email เพื่อสร้างส่วนลด
                  </Typography>
                )}
                <FormItem className="">
                  <FormEmailContainList
                    field={emailContains || []}
                    append={appendEmailContain}
                    control={control}
                    register={register}
                    remove={removeEmailContain}
                    move={moveEmailContain}
                    setValue={setValue}
                    watchEmailContain={emailContains}
                    errors={errors}
                  />
                </FormItem>
              </div>
            </div>
            <TextField
              {...register("course_discount.amount", {
                setValueAs: (value) => {
                  if (value === null) {
                    setValue("course_discount.email_contain", []);
                  }

                  return Number(value);
                },
                min: {
                  value: 0,
                  message: "Amount must be greater than or equal to 0",
                },
                validate: (value) => {
                  if (watch("course_discount.email_contain")?.length > 0) {
                    if (!value || value === 0) {
                      return "Discount amount must greater than 0, if you have email contain";
                    }
                    if (value > watch("price")) {
                      return "Discount amount must less than price";
                    }
                  }
                  return true;
                },
              })}
              error={!!(errors as any)?.course_discount?.amount}
              helperText={(errors as any)?.course_discount?.amount?.message}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              placeholder="0"
              type="number"
              label="ราคาส่วนลด (ถ้ามี)"
              name="course_discount.amount"
            />

            <div className="flex mt-3 gap-2 flex-col">
              <Typography variant="subtitle1" className="text-gray-500">
                แสดงหลักสูตรนี้ให้ผู้ใช้งานเห็นหรือไม่
              </Typography>
              <Select
                className="my-2 max-h-15"
                value={watch("is_show") ? 1 : 0}
                fullWidth
                {...register("is_show", {
                  setValueAs: (value) => Boolean(value),
                })}
              >
                {FormIsShowType.map((item, i) => (
                  <MenuItem key={i} value={item.type}>
                    <div>{item.description}</div>
                  </MenuItem>
                ))}
              </Select>
            </div>

            <Controller
              control={control}
              name="tags"
              rules={{}}
              defaultValue={[]}
              render={({ field: { onChange, value, ref } }) => (
                <Autocomplete
                  ref={ref}
                  multiple
                  id="tags"
                  options={[]}
                  freeSolo
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  value={value}
                  onChange={(_: any, newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tags"
                      placeholder="tag"
                      helperText="กด Enter เพื่อยืนยัน Tag"
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              )}
            />
            <div className="flex flex-col gap-2">
              <Typography variant="h6" style={{ marginTop: "24px" }}>
                หลักสูตรนี้มี Certificate หรือไม่
              </Typography>
              <div>
                <Controller
                  name="enabled_certificate"
                  control={control}
                  defaultValue={false}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...register("enabled_certificate")}
                          checked={value}
                          onChange={(value) => onChange(value)}
                        />
                      }
                      label="มี Certificate"
                    />
                  )}
                />
              </div>
            </div>
            <Box
              display={!watchValues.enabled_certificate ? "none" : "flex"}
              flexDirection={"column"}
            >
              <div className="ml-8 pb-32 flex flex-col gap-4">
                <Typography variant="body2" style={{ marginTop: "20px" }}>
                  ใส่ข้อความเพิ่มเติม
                </Typography>
                <div className="border border-solid">
                  <Editor
                    editorState={contentState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                    }}
                  />
                </div>
                <div>
                  <Button
                    onClick={() => setTimeout(handlePreview, 700)}
                    variant="contained"
                    disabled={loading}
                  >
                    ดูตัวอย่าง certificate
                  </Button>
                </div>
                <div
                  style={{
                    width: "100px",
                    height: "20px",
                    overflow: "hidden",
                  }}
                >
                  <div className="relative h-full" style={{ width: "3508px" }}>
                    <div className="absolute bg-white w-full h-full"></div>
                    {textContent && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: textContent as any,
                        }}
                        ref={descRef}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </Edit>
      </Box>
      {isCertificate && (
        <CertificatePreview
          content_url={certificateUrl}
          setIsCertificate={() => {
            setIsCertificate(false);
            setLoading(false);
          }}
        />
      )}
    </>
  );
};
