import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  ShowButton,
  List,
  DeleteButton,
} from "@pankod/refine-mui";

export const EnrollmentsList = () => {
  const { dataGridProps } = useDataGrid({
    queryOptions: {
      retry: 1,
    },
  });

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "actions",
        headerName: "Action",
        renderCell: function render({ row }) {
          return (
            <>
            <ShowButton hideText recordItemId={row.enrollment_id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
      },
      {
        field: "enrollment_id",
        headerName: "Enrollment Id",
        minWidth: 200,
        type: "number",
        valueFormatter: ({ value }) =>
          value.toLocaleString("en-US", { useGrouping: false }),
        headerAlign: "left",
        align: "left",
      },
      {
        field: "course_name",
        headerName: "Course Name",
        minWidth: 200,
        type: "string",
        headerAlign: "left",
        align: "left",
      },
      {
        field: "user_id",
        headerName: "User Id",
        minWidth: 200,
        type: "number",
        valueFormatter: ({ value }) =>
          Array.isArray(value) ? value.join(", ") : value,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "purchase_history_id",
        headerName: "Purchase History Id (refId)",
        minWidth: 250,
        type: "number",
        valueFormatter: ({ value }) =>
          Array.isArray(value) ? value.join(", ") : value,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 100,
        headerAlign: "left",
        align: "left",
      },

      {
        field: "cancel_at",
        headerName: "Cancel At",
        minWidth: 250,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 250,
        headerAlign: "left",
        align: "left",
      },

      {
        field: "updated_at",
        headerName: "Updated At",
        minWidth: 250,
        headerAlign: "left",
        align: "left",
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        getRowId={(row) => row.enrollment_id}
        columns={columns}
        autoHeight
        disableColumnFilter={true}
      
      />
    </List>
  );
};
