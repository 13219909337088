import { DataProvider } from "@pankod/refine-core";

export const formatError = (err: any) => {
  if (err.message) {
    return err;
  }

  let expectErrorMessage = err?.response?.data?.detail;

  if (Array.isArray(expectErrorMessage)) {
    expectErrorMessage = expectErrorMessage[0].msg;
  }

  err.message = expectErrorMessage;
  return err;
};

type DataProviderMiddleware = Omit<
  Required<DataProvider>,
  "createMany" | "updateMany" | "deleteMany"
>;

export const dataProviderMiddleware = (
  dataProvider: DataProviderMiddleware
): DataProviderMiddleware => {
  const wrappedAuthProvider: Partial<DataProviderMiddleware> = {};

  for (const key in dataProvider) {
    if (
      typeof dataProvider[key as keyof DataProviderMiddleware] === "function"
    ) {
      //@ts-ignore
      wrappedAuthProvider[key] = async (...args: any) => {
        try {
          //@ts-ignore
          return await dataProvider[key](...args);
        } catch (err) {
          throw formatError(err);
        }
      };
    }
  }

  return wrappedAuthProvider as DataProviderMiddleware;
};
