import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

interface Props {
  banner_url?: string;
  title?: string;
  short_dsc?: string;
  content?: [{}];
  is_public?: true;
}

const BlogPreview = ({
  banner_url,
  title,
  short_dsc,
  content,
  is_public,
}: Props) => {
  const [contentState, setContentState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (content) {
      const formatted = convertFromRaw(content[0] as any);
      setContentState(EditorState.createWithContent(formatted));
    }
  }, [content]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="16px"
      padding="24px"
      position={"relative"}
    >
      <Box width="100%">
        {banner_url && (
          <img
            alt={title}
            src={banner_url}
            width="50%"
            style={{ aspectRatio: "2/1" }}
          />
        )}
      </Box>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          <Typography variant="body1" className="italic">
            ชื่อหัวข้อ :
          </Typography>
          <Typography variant="body1">{title || "--"}</Typography>
        </div>
        <div className="flex flex-col gap-1">
          <Typography variant="body1" className="italic">
            คำอธิบาย :
          </Typography>
          <Typography variant="body1">{short_dsc || "--"}</Typography>
        </div>
        <div className="flex flex-col gap-1">
          <Typography variant="body1" className="italic">
            เนื้อหา :
          </Typography>
          {content ? (
            <Editor
              editorState={contentState}
              readOnly={true}
              toolbarClassName="hide-toolbar"
            />
          ) : (
            "--"
          )}
        </div>
        <div className="mt-6">
          <Typography variant="body1" className="italic">
            ตั้งค่าการมองเห็น
          </Typography>

          <FormControlLabel
            control={<Switch checked={is_public} readOnly />}
            label="เผยแพร่เป็นสาธารณะเลย"
          />
        </div>
      </div>
    </Box>
  );
};

export default BlogPreview;
