import { Create, Box, TextField, Typography } from "@pankod/refine-mui";
import {
  Controller,
  useFieldArray,
  useForm,
} from "@pankod/refine-react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { AxiosInstance } from "axios";
import { axiosInstance } from "@pankod/refine-simple-rest";

import { useState, useMemo } from "react";
import FormItem from "components/FormItem";
import { REST_PUBLIC_URI } from "environment";
import FormEmailContainList from "./FormEmailContainList";

export const schema = Yup.object({
  course_id: Yup.number().required(),
  email_contain: Yup.array()
    .of(Yup.string().trim().required())
    .min(1, "At least one email address is required")
    .required(),
  amount: Yup.number().min(0, "Amount must be greater than 0").required(),
});

export interface FormDataCreate {
  course_id: number;
  email_contain: string[];
  amount: number;
}

export const CourseDiscountCreate = () => {
  const {
    saveButtonProps,
    refineCore: { formLoading },
    setValue,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<any, any, FormDataCreate>({
    mode: "onChange",
    defaultValues: {
      email_contain: [],
    },
    resolver: yupResolver(schema),
  });

  const email_contain = useMemo<string[]>(() => {
    if (watch("email_contain")) {
      register("email_contain", { required: true });
      return watch("email_contain");
    } else {
      return [];
    }
  }, [register("email_contain"), watch("email_contain")]);

  const appendEmailContain = (value: string) => {
    setValue("email_contain", [...watch("email_contain"), value]);
  };

  const removeEmailContain = (index: number) => {
    setValue(
      "email_contain",
      watch("email_contain").filter((_, i) => i !== index)
    );
  };

  const moveEmailContain = (from: number, to: number) => {
    const emailContain = watch("email_contain");
    const emailContainCopy = [...emailContain];
    const [removed] = emailContainCopy.splice(from, 1);
    emailContainCopy.splice(to, 0, removed);
    setValue("email_contain", emailContainCopy);
  };

  const renderEmailContain = () => {
    return (
      <div>
        <Typography variant="subtitle1" className="text-gray-500">
          ระบุ email ที่ต้องการให้ได้รับส่วนลด*
        </Typography>
        {errors.email_contain && (
          <Typography variant="caption" color="#fa541c">
            โปรดระบุอย่างน้อย 1 email เพื่อสร้างส่วนลด
          </Typography>
        )}
        <FormItem className="">
          <FormEmailContainList
            field={email_contain}
            append={appendEmailContain}
            control={control}
            register={register}
            remove={removeEmailContain}
            move={moveEmailContain}
            setValue={setValue}
            watchEmailContain={watch("email_contain")}
            errors={errors}
          />
        </FormItem>
      </div>
    );
  };

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("course_id", {
            required: "This field is required",
          })}
          required
          error={!!(errors as any)?.course_id}
          helperText={(errors as any)?.course_id?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          label="Course Id"
          name="course_id"
        />

        <div className="py-6">{renderEmailContain()}</div>

        <TextField
          {...register("amount", {
            required: "This field is required",
            min: {
              value: 0,
              message: "Amount must be greater than or equal to 0",
            },
          })}
          required
          error={!!(errors as any)?.amount}
          helperText={(errors as any)?.amount?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          placeholder="0"
          type="number"
          label="ราคาส่วนลด"
          name="amount"
        />
      </Box>
    </Create>
  );
};
