import { useCallback, useState } from 'react'
import { Crop } from 'react-image-crop'

import { CropOption } from 'types/image-crop'

const MAX_WIDTH = 1280

const useImageCrop = () => {
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<CropOption | null>(null)

  const resetCrop = useCallback(() => {
    setCrop(undefined)
    setCompletedCrop(null)
  }, [])

  /** calculate new image size in case it's too big */
  function calculateSize(width: number, height: number, maxWidth: number) {
    // no height check because width always more than height due to aspect ratio
    if (width > maxWidth) {
      height = Math.round((maxWidth / width) * height)
      width = maxWidth
    }
    return [width, height]
  }

  async function getCroppedImg(image: HTMLImageElement, crop: CropOption): Promise<Blob | null> {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const [newWidth, newHeight] = calculateSize(crop.width * scaleX, crop.height * scaleY, MAX_WIDTH)
    canvas.width = newWidth
    canvas.height = newHeight
    const ctx = canvas.getContext('2d')

    if (!ctx) return null

    // New lines to be added
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      newWidth,
      newHeight
    )

    // As Base64 string
    // const base64Image = canvas.toDataURL("image/jpeg");
    // return base64Image;

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) return reject(null)
        resolve(blob)
      }, 'image/png')
    })
  }

  return {
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    getCroppedImg,
    resetCrop,
  }
}

export default useImageCrop
