import React from "react";
import { TextField } from "@mui/material";
import {
  Control,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  FieldErrors,
} from "react-hook-form";
import { MdDelete } from "react-icons/md";
import { FormDataCreate } from "./create";
import { IconButton } from "@pankod/refine-mui";
import { CourseTime } from "utils/util";

type BaseDivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface FormCardProps extends BaseDivProps {
  nestIndex: number;
  control: Control<FormDataCreate>;
  register: UseFormRegister<FormDataCreate>;
  courseTimes: CourseTime;
  setValue: UseFormSetValue<FormDataCreate>;
  removeTime: UseFieldArrayRemove;
  watch: UseFormWatch<FormDataCreate>;
  errors: FieldErrors<FormDataCreate>;
  disabled?: boolean;
}

const FormTimeCard = React.forwardRef<HTMLDivElement, FormCardProps>(
  (
    {
      nestIndex,
      control,
      register,
      courseTimes,
      setValue,
      removeTime,
      className,
      watch,
      errors,
      disabled,
      ...rest
    },
    ref
  ) => {
    const validateDateTimeRange = (value?: string | Date) => {
      if (!value) return false;
      const startedAt = watch(`course_times.${nestIndex}.start_at`);
      const endedAt = value as string;
      if (startedAt && endedAt && endedAt < startedAt) {
        return "Second datetime cannot be earlier than the first datetime";
      }
      return true;
    };

    return (
      <div
        className={`flex flex-col items-center w-full border p-4 gap-4 mb-3 `}
        ref={ref}
        {...rest}
      >
        <div className="w-full flex gap-2">
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            inputProps={{
              ...register(`course_times.${nestIndex}.start_at` as const, {
                required: true,
                validate: validateDateTimeRange,
              }),
            }}
            error={
              (errors as any)?.course_times &&
              (errors as any)?.course_times?.length - 1 >= nestIndex
                ? !!(errors as any)?.course_times[`${nestIndex}`]?.start_at
                : false
            }
            type="datetime-local"
            label="เริ่มเรียน"
            variant="filled"
            name={`course_times[${nestIndex}].start_at`}
            // value={new Date(courseTimes.start_at).toISOString().slice(0, 16)}
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            inputProps={{
              ...register(`course_times.${nestIndex}.end_at` as const, {
                required: true,
                validate: validateDateTimeRange,
              }),
            }}
            error={
              (errors as any)?.course_times &&
              (errors as any)?.course_times?.length - 1 >= nestIndex
                ? !!(errors as any)?.course_times[`${nestIndex}`]?.end_at
                : false
            }
            type="datetime-local"
            label="เลิกเรียน"
            variant="filled"
            name={`course_times[${nestIndex}].end_at`}
          />
        </div>

        {!disabled && (
          <>
            <hr className=" w-full" />
            <div className="flex w-full justify-end">
              <IconButton
                className="p-2 self-end"
                onClick={() => removeTime(nestIndex)}
              >
                <MdDelete className="text-lg" size={24} />
              </IconButton>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default FormTimeCard;
