import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  EditButton,
  List,
  ShowButton,
} from "@pankod/refine-mui";

export const LecturerList = () => {
  const { dataGridProps } = useDataGrid({
    queryOptions: {
      retry: 1,
    },
  });

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "actions",
        headerName: "Action",
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.lecturer_id} />
              <ShowButton hideText recordItemId={row.lecturer_id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
      },
      {
        field: "No.",
        headerName: "No.",
        minWidth: 80,
        type: "number",
        headerAlign: "left",
        align: "left",
        renderCell: (index: any) =>
          index.api.getRowIndex(index.row.lecturer_id) + 1,
      },
      {
        field: "lecturer_id",
        headerName: "Lecturer ID",
        minWidth: 200,
        type: "number",
        valueFormatter: ({ value }) =>
          value.toLocaleString("en-US", { useGrouping: false }),
        headerAlign: "left",
        align: "left",
      },
      {
        field: "surname",
        headerName: "ชื่อ",
        minWidth: 200,
        type: "text",
        headerAlign: "left",
        align: "left",
      },
      {
        field: "lastname",
        headerName: "นามสกุล",
        minWidth: 200,
        type: "text",
        headerAlign: "left",
        align: "left",
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        getRowId={(row) => row.lecturer_id}
        columns={columns}
        autoHeight
        disableColumnFilter={true}
      />
    </List>
  );
};
