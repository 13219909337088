import { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";

import Modal from "./Modal";

import useImageCrop from "hooks/useImageCrop";
import { CropOption } from "types/image-crop";
import { Button } from "@mui/material";
import ErrorBox from "components/ErrorBox";

interface Props {
  title: string;
  titleSubmit: string;
  titleCancel?: string;
  isShow: boolean;
  onClose: () => void;
  imgFile: File | undefined;
  onSubmit: (image: File) => Promise<void | string>;
  option?: Partial<CropOption>;
  circularCrop?: boolean;
}

const ModalImageCrop = ({
  title,
  titleSubmit,
  titleCancel,
  imgFile,
  onSubmit,
  isShow,
  option,
  circularCrop,
  onClose,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [src, setSrc] = useState("");
  const imgRef = useRef(null);
  const {
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    getCroppedImg,
    resetCrop,
  } = useImageCrop();

  const onLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const { width, height } = e.currentTarget;
      if (crop) {
        return setCrop(crop);
      }
      return setCrop(
        centerCrop(
          makeAspectCrop(
            {
              unit: "%",
              x: 25,
              y: 25,
              width: 70,
              height: 70,
            },
            option?.aspect || 1,
            width,
            height
          ),
          width,
          height
        )
      );
    },
    [setCrop, option, crop]
  );

  const onConfirm = async () => {
    setLoading(true);
    try {
      if (!completedCrop || !imgRef.current) return;
      const croppedBlob = await getCroppedImg(imgRef.current, completedCrop);
      if (!croppedBlob) return;
      const croppedImage = new File([croppedBlob], "profile", {
        type: "image/png",
      });
      const errMessage = await onSubmit(croppedImage);
      if (errMessage) setErrorMessage(errMessage);
    } catch (err: any) {
      setErrorMessage(err?.response?.data.detail);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (imgFile) {
      if (src) URL.revokeObjectURL(src);
      setSrc(URL.createObjectURL(imgFile));
      resetCrop();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgFile, resetCrop]);

  return (
    <Modal isShow={isShow} setIsShow={() => onClose()} title={title}>
      <div>
        <ErrorBox
          className="my-2 mx-10"
          message={errorMessage}
          onClose={() => setErrorMessage("")}
          open={!!errorMessage}
        />
        <div className="flex justify-center ">
          <ReactCrop
            aspect={circularCrop ? 1 : option?.aspect || 1}
            circularCrop={circularCrop}
            className="max-h-[60vh]"
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          >
            <img
              alt="img crop"
              className="object-contain"
              onLoad={onLoad}
              ref={imgRef}
              src={src}
            />
          </ReactCrop>
        </div>
        {circularCrop ? (
          <div className="mt-5 flex justify-center">
            <Button
              disabled={loading}
              fullWidth
              onClick={onConfirm}
              variant="outlined"
            >
              {titleSubmit}
            </Button>
          </div>
        ) : (
          <div className="mt-5 flex justify-between">
            <Button
              fullWidth
              onClick={onClose}
              variant="outlined"
              color="error"
            >
              {titleCancel}
            </Button>
            <div className="m-2.5" />
            <Button
              disabled={loading}
              fullWidth
              onClick={onConfirm}
              variant="outlined"
            >
              {titleSubmit}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalImageCrop;
