import { Edit, Box, TextField } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PaymentsDataCreate, schema } from "./create";

export const ManualPaymentsEdit = () => {
  const {
    saveButtonProps,
    refineCore: { formLoading },
    setValue,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<any, any, PaymentsDataCreate>({
    mode: "onChange",
    defaultValues: {
      ref1: "",
      ref2: "",
      ref3: "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <Edit
      canDelete={false}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("purchase_history_id", {
            required: "This field is required",
          })}
          required
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          label="purchase_history_id"
          name="purchase_history_id"
          disabled={true}
        />

        <div className="flex gap-4">
          <TextField
            {...register("amount", {
              required: "This field is required",
              min: {
                value: 0,
                message: "Amount must be greater than or equal to 0",
              },
            })}
            error={!!(errors as any)?.amount}
            helperText={(errors as any)?.amount?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="number"
            label="amount"
            name="amount"
          />
          <TextField
            {...register("transaction_id")}
            error={!!(errors as any)?.transaction_id}
            helperText={(errors as any)?.transaction_id?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="transaction_id"
            name="transaction_id"
          />
        </div>

        <TextField
          {...register("ref1")}
          required
          error={!!(errors as any)?.ref1}
          helperText={(errors as any)?.ref1?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Ref1"
          name="ref1"
        />

        <TextField
          {...register("ref2")}
          required
          error={!!(errors as any)?.ref2}
          helperText={(errors as any)?.ref2?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Ref2"
          name="ref2"
        />

        <TextField
          {...register("ref3")}
          required
          error={!!(errors as any)?.ref3}
          helperText={(errors as any)?.ref3?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Ref3"
          name="ref3"
        />
      </Box>
    </Edit>
  );
};
