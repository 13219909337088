import { Stack, Typography } from "@mui/material";
import { CourseQueryDataType } from "./show";
import {
  formatDateTime24Hr,
  renderCourseTime,
  totalCourseDuration,
} from "utils/util";
import { useMemo } from "react";

interface Props {
  courseData: CourseQueryDataType | undefined;
}

const HeaderTypography = (text: string) => {
  return (
    <Typography
      className="text-black"
      fontWeight="medium"
      sx={{ marginTop: 1 }}
      variant="subtitle1"
    >
      {text}
    </Typography>
  );
};

const DetailTypography = (text: string | number) => {
  return (
    <Typography
      className="text-black"
      fontWeight="normal"
      style={{ whiteSpace: "pre-line" }}
      variant="subtitle2"
    >
      {text}
    </Typography>
  );
};

const CourseDetail = ({ courseData }: Props) => {
  const emailContain = courseData?.course_discount?.email_contain;

  const lecturerName = useMemo(() => {
    const lecturer = courseData?.lecturer;
    if (lecturer?.lecturer_id) {
      return `${lecturer.prefix} ${lecturer.surname} ${lecturer.lastname}`;
    }
  }, [courseData]);

  return (
    <Stack ml={1} mt={3} gap={0.2}>
      {HeaderTypography("Course ID")}
      {DetailTypography(courseData?.course_id ?? "")}
      {HeaderTypography("ชื่อหลักสูตร")}
      {DetailTypography(courseData?.name ?? "")}
      {HeaderTypography("วิทยากร")}
      {DetailTypography(lecturerName ?? "-")}
      {HeaderTypography("คำโปรย")}
      {DetailTypography(courseData?.tag_line ?? "")}
      {HeaderTypography("คำอธิบาย")}
      {DetailTypography(courseData?.description ?? "")}
      {HeaderTypography("แท็ก")}
      <div className="flex flex-wrap gap-2">
        {courseData?.tags.length
          ? courseData?.tags.map((tag, index) => (
              <div
                key={index}
                className="bg-gray-300 text-black px-2 py-1 rounded flex"
              >
                #{DetailTypography(tag || "-")}
              </div>
            ))
          : DetailTypography("-")}
      </div>
      {HeaderTypography("เวลา")}
      {courseData?.course_times.map((courseTime, i) => (
        <div key={i}>
          {DetailTypography(`${"\u2022"} ${renderCourseTime(courseTime)}`)}
        </div>
      ))}
      {HeaderTypography("จำนวนชั่วโมง")}
      {courseData &&
        DetailTypography(`${totalCourseDuration(courseData.course_times)}`)}
      {HeaderTypography("สถานที่")}
      {DetailTypography(courseData?.location ?? "")}
      {HeaderTypography("เปิดลงทะเบียน")}
      {courseData &&
        DetailTypography(
          `${formatDateTime24Hr(courseData.start_at)} ถึง ${formatDateTime24Hr(
            courseData.close_at
          )}`
        )}
      {HeaderTypography("ราคา (บาท)")}
      {DetailTypography(courseData?.price.toLocaleString() ?? "")}
      {HeaderTypography("email ที่มีสิทธ์ได้รับส่วนลด (ถ้ามี)")}
      {emailContain
        ? emailContain?.map((email, i) => {
            return (
              <div key={i}>{DetailTypography(`${"\u2022"} ${email}`)}</div>
            );
          })
        : DetailTypography("-")}
      {HeaderTypography("ส่วนลด (บาท)")}
      {DetailTypography(
        courseData?.course_discount?.amount.toLocaleString() ?? "-"
      )}
      {HeaderTypography("คำถามอื่นๆ")}
      {courseData?.course_infos && courseData?.course_infos.length > 0
        ? courseData?.course_infos.map((info, i) => {
            return (
              <div key={i}>
                {DetailTypography(`${i + 1}. ${info.info_name}`)}
              </div>
            );
          })
        : DetailTypography("-")}
      {courseData?.banner_url && (
        <>
          {HeaderTypography("รูปประกอบ")}
          <img
            src={courseData?.banner_url}
            alt={courseData?.name}
            className="max-w-4xl"
          ></img>
        </>
      )}
    </Stack>
  );
};

export default CourseDetail;
