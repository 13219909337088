import { MdOutlineInfo } from "react-icons/md";

interface Props {
  open: boolean;
  message: string;
  onClose?: () => void;
  className?: string;
}

/*
  This component is used for showing response error message
*/

const ErrorBox = ({ open, message, onClose, className = "" }: Props) => {
  if (!open) return null;

  return (
    <div
      className={`${className} bg-red-100 p-2 flex items-center text-center`}
      style={{ borderRadius: 4 }}
    >
      <div className="cursor-pointer" onClick={onClose}>
        <MdOutlineInfo height={"24px"} width={"24px"} />
      </div>
      <div className="px-1" />
      <p className="text-error">{message}</p>
    </div>
  );
};

export default ErrorBox;
