import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  Control,
  UseFieldArrayAppend,
  UseFieldArrayMove,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  FieldErrors,
} from "react-hook-form";
import { FormDataCreate } from "./create";
import FormCard from "./FormCard";
import { Button } from "@pankod/refine-mui";
import { useWatch } from "@pankod/refine-react-hook-form";

interface FormListProps {
  control: Control<FormDataCreate>;
  register: UseFormRegister<FormDataCreate>;
  setValue: UseFormSetValue<FormDataCreate>;
  move: UseFieldArrayMove;
  append: UseFieldArrayAppend<FormDataCreate, "course_infos">;
  remove: UseFieldArrayRemove;
  errors: FieldErrors<FormDataCreate>;
  disabled?: boolean;
}

const FormList = ({
  control,
  register,
  setValue,
  move,
  append,
  remove,
  errors,
  disabled,
}: FormListProps) => {
  const courseInfos = useWatch({ control, name: "course_infos" });
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    move(result.source.index, result.destination.index);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {courseInfos.map((courseInfo, i) => {
                return (
                  <FormCard
                    control={control}
                    disabled={disabled}
                    formItem={courseInfo}
                    nestIndex={i}
                    register={register}
                    removeCard={remove}
                    setValue={setValue}
                    errors={errors}
                    key={"course_draggable" + i}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!disabled && (
        <div className="flex mt-2 gap-x-2">
          <Button
            onClick={() =>
              append({
                info_name: "",
                info_type: "RADIO",
                info_choice: [],
              })
            }
            type="button"
            variant="outlined"
          >
            เพิ่มคำถาม
          </Button>
        </div>
      )}
    </>
  );
};

export default FormList;
