import { useShow } from "@pankod/refine-core";
import { CourseQueryDataType } from "./show";
import {
  Button,
  DataGrid,
  GridColumns,
  ShowButton,
  useDataGrid,
} from "@pankod/refine-mui";
import IconDownload from "components/SvgIcons/IconDownload";
import { useMemo } from "react";
import { MdCircle } from "react-icons/md";
import { CsvSchema } from "types/general";
import { formatDateTime24Hr, formatYearMonthDay } from "utils/util";
import { ProfileQueryDataType } from "pages/users";

interface Props {
  courseData?: CourseQueryDataType;
}

const additionalSchema: CsvSchema[] = [
  {
    headerName: "สมัครด้วย",
    field: "register_by",
  },
];

const profileSchema: CsvSchema<ProfileQueryDataType>[] = [
  {
    headerName: "image url",
    field: "image_url",
  },
  {
    headerName: "name title",
    field: "prefix",
  },
  {
    headerName: "firstname",
    field: "first_name",
  },
  {
    headerName: "surname",
    field: "sur_name",
  },
  {
    headerName: "gender",
    field: "gender",
  },
  {
    headerName: "birth date",
    field: "birth_date",
  },
  {
    headerName: "personal id",
    field: "personal_id",
  },
  {
    headerName: "phone no",
    field: "phone_no",
  },
  {
    headerName: "email",
    field: "email",
  },
  {
    headerName: "address",
    field: "address",
  },
  {
    headerName: "occupation",
    field: "occupation",
  },
  {
    headerName: "custom occupation",
    field: "custom_occupation",
  },
  {
    headerName: "company name",
    field: "company_name",
  },
  {
    headerName: "invoice address same as profile",
    field: "invoice_same_as_profile",
    formatter: (v) => (v ? "yes" : "no"),
  },
  {
    headerName: "raw invoice address",
    field: "primary_invoice_address",
    formatter: (v) => {
      delete v.invoice_address_id;
      delete v.user_id;
      delete v.created_at;
      delete v.updated_at;
      return JSON.stringify(v)
        .replaceAll(",", ";")
        .replace("{", "")
        .replace("}", "");
    },
  },
  {
    headerName: "invoice address",
    field: "primary_invoice_address",
    formatter: (v) => {
      delete v.invoice_address_id;
      delete v.user_id;
      delete v.created_at;
      delete v.updated_at;
      return `ชื่อ ${v.name || ""} ที่อยู่ ${
        v.address
      } หมายเลขประจําตัวผู้เสียภาษีอากร ${v.invoice_number} โทร ${v.phone_no}`;
    },
  },
  {
    headerName: "ภ.พ. 20",
    field: "primary_invoice_address",
    formatter: (v) => {
      delete v.invoice_address_id;
      delete v.user_id;
      delete v.created_at;
      delete v.updated_at;
      return v.vat_license_image_url || "";
    },
  },
];

const CourseMember = ({ courseData }: Props) => {
  const { queryResult: profileResult } = useShow<ProfileQueryDataType[]>({
    resource: "profiles/course",
    id: courseData?.course_id,
  });

  const mapUserIdToProfileId = useMemo(() => {
    const mapper: Record<number, number> = {};

    profileResult.data?.data.forEach((profile) => {
      mapper[profile.user_id] = profile.profile_id;
    });

    return mapper;
  }, [profileResult]);

  const { dataGridProps } = useDataGrid({
    resource: "enrollments",
    permanentFilter: [
      {
        field: "course_id",
        operator: "eq",
        value: courseData?.course_id,
      },
    ],
    queryOptions: {
      retry: 1,
    },
  });

  dataGridProps.rows.forEach((item, index) => {
    item.row_number = index + 1;
  });

  const questionsColumns = useMemo<GridColumns<any>>(() => {
    const questions: string[] = dataGridProps.rows[0]?.question || [];
    const questionColumns = questions.map((question, index) => ({
      field: `question_${index}`,
      headerName: question,
      minWidth: 150,
      valueGetter: function (params: { row: any }) {
        return params.row.course_infos[index];
      },
    }));

    return questionColumns;
  }, [dataGridProps.rows]);

  const renderStatus = (status: string) => {
    switch (status) {
      case "PENDING":
        return (
          <div className="flex flex-row items-center">
            <MdCircle className="mt-0.5 mr-1" color="#FFA500" />
            <div className="font-medium text-yellow-500">กำลังทำรายการ</div>
          </div>
        );
      case "SUCCESS":
        return (
          <div className="flex flex-row items-center">
            <MdCircle className="mt-0.5 mr-1" color="#02C755" />
            <div className="font-medium text-green-500">สำเร็จ</div>
          </div>
        );
      default:
        return (
          <div className="flex flex-row items-center">
            <MdCircle className="mt-0.5 mr-1" color="#EF4E4E" />
            <div className="font-medium text-red-500">ไม่สำเร็จ</div>
          </div>
        );
    }
  };

  const columns = useMemo<GridColumns<any>>(
    () => [
      {
        field: "actions",
        headerName: "ดูข้อมูล",
        renderCell: function render({ row }) {
          return (
            <>
              <ShowButton
                hideText
                recordItemId={
                  mapUserIdToProfileId[row.user_id]?.toString() || ""
                }
                resourceNameOrRouteName="users"
                id={mapUserIdToProfileId[row.user_id]?.toString() || ""}
              />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
      },
      {
        field: "row_number",
        headerName: "No.",
        renderCell: function render({ value }) {
          return value;
        },
        align: "left",
        headerAlign: "left",
        minWidth: 80,
        sortable: false,
      },
      {
        field: "user_id",
        headerName: "User ID",
        minWidth: 150,
      },
      {
        field: "username",
        headerName: "Account Name",
        minWidth: 200,
      },
      {
        field: "status",
        headerName: "สถานะ",
        minWidth: 150,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => renderStatus(params.value as string),
      },
      {
        field: "success_at",
        headerName: "จ่ายเงินสำเร็จเมื่อ",
        minWidth: 200,
        renderCell: (params) => {
          const successAt = params.value as string;
          return successAt ? formatDateTime24Hr(successAt) : "-";
        },
      },
      {
        field: "purchase_history_id",
        headerName: "Purchase History Id (refId)",
        minWidth: 200,
      },
      ...questionsColumns,
    ],
    [questionsColumns, mapUserIdToProfileId]
  );

  const profileMapper = useMemo(() => {
    let mapper: Map<number, ProfileQueryDataType> = new Map();
    if (profileResult.data?.data) {
      mapper = profileResult.data.data.reduce((mapAccumulator, obj) => {
        mapAccumulator.set(obj["user_id"], obj);

        return mapAccumulator;
      }, new Map());
    }
    return mapper;
  }, [profileResult, dataGridProps.rows]);

  const handleDownload = () => {
    const csvContent = generateCsvContent();
    const BOM = "\uFEFF";
    const textEncoder = new TextEncoder();
    const csvData = textEncoder.encode(BOM + csvContent);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Report_${courseData?.course_id}_${formatYearMonthDay(
      new Date()
    )}.csv`;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateCsvContent = () => {
    let headerRow = columns
      .filter((column) => column.field !== "actions")
      .map((column) => `"${column.headerName}"`);

    headerRow = headerRow.concat(additionalSchema.map((ps) => ps.headerName));
    headerRow = headerRow.concat(["price"]);
    headerRow = headerRow.concat(profileSchema.map((ps) => ps.headerName));

    const dataRows = dataGridProps.rows.map((row) => {
      const rowData = columns
        .map((column) => {
          if (
            column.field === "user_id" ||
            column.field === "purchase_history_id"
          ) {
            return `'${row[column.field] || ""}`;
          } else if (column.field.startsWith("question_")) {
            const questionIndex = parseInt(
              column.field.replace("question_", ""),
              10
            );
            return `"${row.course_infos[questionIndex] || ""}"`;
          } else if (column.field === "status") {
            return `"${
              row[column.field] === "PENDING"
                ? "กำลังทำรายการ"
                : row[column.field] === "SUCCESS"
                ? "สำเร็จ"
                : "ไม่สำเร็จ"
            }"`;
          }
          return `"${row[column.field] || ""}"`;
        })
        .filter((_, index) => columns[index].field !== "actions");

      rowData.push(`"'${row.register_by}"`);
      // add final price
      rowData.push(`"${row.invoice_price || ""}"`);

      const profile = profileMapper.get(row.user_id);

      for (const schema of profileSchema) {
        if (!profile) {
          rowData.push(`"-"`);
          continue;
        }

        const value = profile[schema.field];

        if (value === undefined || value === null) {
          rowData.push(`"-"`);
          continue;
        }

        if (schema.formatter) {
          rowData.push(`"${schema.formatter(value)}"`);
          continue;
        }

        if (typeof value === "number") {
          rowData.push(`"'${value}"`);
          continue;
        }

        if (/^\d+$/.test(value as string)) {
          rowData.push(`"'${value}"`);
          continue;
        }

        rowData.push(`"${value}"`);
      }

      return rowData.join();
    });

    const csvContent = [headerRow.join(","), ...dataRows].join("\n");

    return csvContent;
  };

  return (
    <>
      <div className="px-4 py-7">
        <span className="text-gray-500 mr-8" style={{ fontSize: "16px" }}>
          ดาวน์โหลดไฟล์
        </span>
        <Button
          onClick={handleDownload}
          variant="contained"
          startIcon={<IconDownload />}
        >
          Report
        </Button>
      </div>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        getRowId={(row) => row.enrollment_id}
        autoHeight
      />
    </>
  );
};

export default CourseMember;
