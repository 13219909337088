import React from "react";
import { Show, Typography } from "@pankod/refine-mui";
import { useShow } from "@pankod/refine-core";
import { BsPersonCircle } from "react-icons/bs";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState } from "draft-js";

export interface LecturerQueryDataType {
  lecturer_id: number;
  image_url: string;
  prefix: string;
  surname: string;
  lastname: string;
  position: string;
  short_bio: string;
  is_public: boolean;
  created_at: string;
  updated_at: string;
}

export const LecturerShow = () => {
  const { queryResult } = useShow<LecturerQueryDataType>();
  const { data, isLoading } = queryResult;
  const lecturer = data?.data;

  const ShortBio = (short_bio: string) => {
    if (short_bio.startsWith("{")) {
      try {
        const bio = JSON.parse(short_bio);
        const editorState = EditorState.createWithContent(convertFromRaw(bio));
        return (
          <Editor
            readOnly={true}
            toolbarClassName="hide-toolbar"
            editorState={editorState}
          />
        );
      } catch {
        return <Typography>{`(ข้อมูลไม่ถูกต้อง) ${short_bio}`}</Typography>;
      }
    }

    return <Typography>{short_bio}</Typography>;
  };

  const Position = (position: string) => {
    if (position.startsWith("{")) {
      try {
        const pos = JSON.parse(position);
        const editorState = EditorState.createWithContent(convertFromRaw(pos));
        return (
          <Editor
            readOnly={true}
            toolbarClassName="hide-toolbar"
            editorState={editorState}
          />
        );
      } catch {
        return <Typography>{`(ข้อมูลไม่ถูกต้อง) ${position}`}</Typography>;
      }
    }

    return <Typography>{position}</Typography>;
  };

  return (
    <Show
      isLoading={isLoading}
      title={<Typography variant="h5">วิทยากร</Typography>}
    >
      <div className="border border-solid shadow-md p-6">
        <Typography variant="h6">ข้อมูลวิทยากร</Typography>
        <div className="mt-2 px-3 flex flex-col gap-4">
          <Typography>
            สถานะ:
            <Typography className="inline pl-2">
              {lecturer?.is_public
                ? "เผยแพร่เป็นสาธารณะแล้ว"
                : "ยังไม่เผยแพร่เป็นสาธารณะ"}
            </Typography>
          </Typography>

          <div className="grid grid-cols-5">
            <Typography className="">Lecturer ID</Typography>
            <Typography className="col-span-4">
              {lecturer?.lecturer_id}
            </Typography>
          </div>
          <div className="flex gap-4 items-center">
            {lecturer?.image_url ? (
              <img
                alt={lecturer?.surname}
                className="w-[40px] h-[40px] rounded-full"
                src={lecturer?.image_url}
              />
            ) : (
              <BsPersonCircle size={32} />
            )}
            <Typography variant="h6">{`${lecturer?.prefix || ""} ${
              lecturer?.surname || ""
            } ${lecturer?.lastname || ""}`}</Typography>
          </div>

          <div className="grid grid-cols-5">
            <Typography className="">ตำแหน่ง</Typography>
            <div className="col-span-4">
              {Position(lecturer?.position || "")}
            </div>
          </div>
          <div className="grid grid-cols-5">
            <Typography className="">ประวัติโดยย่อ</Typography>
            <div className="col-span-4">
              {ShortBio(lecturer?.short_bio || "")}
            </div>
          </div>
        </div>
      </div>
    </Show>
  );
};
