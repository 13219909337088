import {
  Button,
  DeleteButton,
  MenuItem,
  SaveButton,
  Select,
  Show,
  TextField,
  Typography,
} from "@pankod/refine-mui";
import { useEffect, useRef } from "react";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { AxiosInstance } from "axios";
import { axiosInstance } from "@pankod/refine-simple-rest";
import useModalState from "hooks/useModalState";
import { useState } from "react";
import { REST_PUBLIC_URI } from "environment";
import { InvoiceAddressEditDataType } from "pages/users";
import { useShow } from "@pankod/refine-core";
import { useParams } from "@pankod/refine-react-router-v6";
import { formatError } from "utils/request";

interface InvoiceAddressBox {
  index: number;
  addr: InvoiceAddressEditDataType;
}

interface data {
  file_url: string;
}

interface fileUpload {
  data: data;
}

const httpClient: AxiosInstance = axiosInstance;

const upload = async (base64: File) => {
  const x = new FormData();
  x.append("file", base64);
  const httpClient: AxiosInstance = axiosInstance;
  const url = `${REST_PUBLIC_URI}/api/v1/admin/files/upload_file`;
  const data: fileUpload = await httpClient.post(url, x);
  return data;
};

const InvoiceAddressBox: React.FC<InvoiceAddressBox> = ({ index, addr }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);

  const {
    register,
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
  } = useForm<any, any, InvoiceAddressEditDataType>({
    defaultValues: addr,
  });

  const vatLicenseUrl = watch("vat_license_image_url");
  const invoiceAddressId = watch("invoice_address_id");

  const onSubmit = async (v: InvoiceAddressEditDataType) => {
    setUpdating(true);
    try {
      if (!v.invoice_address_id) {
        const url = `${REST_PUBLIC_URI}/api/v1/admin/invoice-addresses`;
        const createdInvoiceAddress = await httpClient.post(url, v);
        v.invoice_address_id = createdInvoiceAddress.data.invoice_address_id;
      }

      if (v.invoice_address_id) {
        const url = `${REST_PUBLIC_URI}/api/v1/admin/invoice-addresses/${v.invoice_address_id}`;
        const data = await httpClient.patch(url, v);
        setValue("invoice_address_id", data.data.invoice_address_id);
      }
    } catch (err: any) {
      formatError(err);
      window.alert(err.message);
    }
    setUpdating(false);
  };

  const handleDelete = async (v: InvoiceAddressEditDataType) => {
    setDeleting(true);
    try {
      const url = `${REST_PUBLIC_URI}/api/v1/admin/invoice-addresses/${v.invoice_address_id}`;
      await httpClient.delete(url);
      window.location.reload();
    } catch (err: any) {
      formatError(err);
      window.alert(err.message);
    }
    setDeleting(false);
  };

  const handleUploadFile = async (file: File | undefined) => {
    if (!file) {
      return;
    }

    setLoading(true);
    try {
      const file_url = await upload(file);
      await setValue("vat_license_image_url", file_url.data.file_url, {
        shouldDirty: true,
      });
      setLoading(false);
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        window.alert(error.response.data.detail);
      } else {
        window.alert(error.message || "Something went wrong");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (addr) {
      setValue("address", addr.address);
      setValue("invoice_address_id", addr.invoice_address_id);
      setValue("invoice_number", addr.invoice_number);
      setValue("name", addr.name);
      setValue("phone_no", addr.phone_no);
      setValue("type", addr.type);
      setValue("user_id", addr.user_id);
      setValue("vat_license_image_url", addr.vat_license_image_url);
    }
  }, [addr, setValue]);

  return (
    <div className={`flex flex-col w-full border p-4 gap-4 mb-3 `}>
      <div className="flex items-center">
        <Typography variant="caption">
          {`ที่อยู่ ${index + 2} (สำหรับออกใบเสร็จ / กำกับภาษี)`}
        </Typography>

        <div className="flex-1" />

        <SaveButton
          onClick={handleSubmit(onSubmit)}
          disabled={!isDirty || updating}
        >
          {invoiceAddressId ? "save" : "create"}
        </SaveButton>

        <div className="px-1" />

        <DeleteButton
          onClick={handleSubmit(handleDelete)}
          variant="outlined"
          disabled={deleting || !watch("invoice_address_id")}
        >
          delete
        </DeleteButton>
      </div>

      <TextField {...register("user_id")} disabled label="user id" />

      <TextField
        {...register("invoice_address_id")}
        disabled
        label="invoice address id"
        InputLabelProps={{ shrink: true }}
      />

      <div className="flex mt-2 gap-1 flex-col">
        <Typography variant="subtitle1" className="text-gray-500">
          ประเภท
        </Typography>
        <Controller
          name="type"
          control={control}
          defaultValue="PERSONAL"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              {...register("type")}
              onChange={(newValue) =>
                onChange(newValue.target.value as "PERSONAL" | "COMPANY")
              }
            >
              <MenuItem value={"PERSONAL"}>
                <div>PERSONAL</div>
              </MenuItem>
              <MenuItem value={"COMPANY"}>
                <div>COMPANY</div>
              </MenuItem>
            </Select>
          )}
        />
      </div>

      <TextField
        {...register("name", {
          required: "This field is required",
        })}
        required
        error={!!(errors as any)?.address}
        helperText={(errors as any)?.address?.message}
        margin="normal"
        fullWidth
        type="text"
        label="ชื่อ"
        InputLabelProps={{ shrink: true }}
        placeholder="ใส่ข้อความที่นี่"
        name="name"
      />
      <TextField
        {...register("address", {
          required: "This field is required",
        })}
        required
        error={!!(errors as any)?.address}
        helperText={(errors as any)?.address?.message}
        margin="normal"
        fullWidth
        type="text"
        label="ที่อยู่"
        // label={`ที่อยู่ ${addressIndex + 2} (สำหรับออกใบเสร็จ / กำกับภาษี)`}
        InputLabelProps={{ shrink: true }}
        placeholder="ใส่ข้อความที่นี่"
        name="address"
      />
      <TextField
        {...register("phone_no", {
          required: "This field is required",
        })}
        required
        error={!!(errors as any)?.phone_no}
        helperText={(errors as any)?.phone_no?.message}
        margin="normal"
        fullWidth
        type="text"
        label="เบอร์"
        InputLabelProps={{ shrink: true }}
        placeholder="ใส่ข้อความที่นี่"
        name="phone_no"
      />
      <TextField
        {...register("invoice_number", {
          required: "This field is required",
        })}
        required
        error={!!(errors as any)?.invoice_number}
        helperText={(errors as any)?.invoice_number?.message}
        margin="normal"
        fullWidth
        type="text"
        label="หมายเลขประจําตัวผู้เสียภาษีอากร"
        InputLabelProps={{ shrink: true }}
        placeholder="ใส่ข้อความที่นี่"
        name="invoice_number"
      />

      <div>
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          accept="application/pdf,image/*,"
          onChange={(e) => handleUploadFile(e.target.files?.[0])}
        />

        <Typography>Vat license</Typography>
        <div className="flex gap-4">
          <Typography
            className="flex-1 p-2 rounded-md"
            style={{ border: "1px solid grey" }}
          >
            {vatLicenseUrl}
          </Typography>
          <Button
            className="w-20"
            variant="outlined"
            disabled={loading}
            onClick={() => inputRef.current?.click()}
          >
            upload
          </Button>
          <Button
            className="w-20"
            variant="outlined"
            color="error"
            disabled={loading}
            onClick={() =>
              setValue("vat_license_image_url", null, { shouldDirty: true })
            }
          >
            delete
          </Button>
        </div>
      </div>
    </div>
  );
};

interface DataProps {
  items: InvoiceAddressEditDataType[];
  total: number;
  limit: number;
  offset: number;
}

export const InvoiceAddressShow = () => {
  const params = useParams();
  const { isShow, onClose, onOpen } = useModalState();
  const [addrs, setAddrs] = useState<InvoiceAddressEditDataType[]>([]);
  const { queryResult } = useShow();

  const userId = params.id;
  // const invoiceAddresses: InvoiceAddressEditDataType[] =
  //   queryResult.data?.data.items;

  // console.log("q", queryResult.data?.data.items);
  useEffect(() => {
    setAddrs(queryResult.data?.data.items);
  }, [queryResult.data?.data.items]);

  const handleCreateEmptyAddr = () => {
    setAddrs((prev) => [
      ...prev,
      {
        invoice_address_id: null,
        address: "",
        invoice_number: "",
        name: "",
        phone_no: "",
        type: "PERSONAL",
        user_id: Number(userId),
        vat_license_image_url: null,
      },
    ]);
  };

  return (
    <Show
      // isLoading={isLoading}
      title={
        <Typography variant="h5" color="textPrimary">
          แก้ไขข้อมูล
        </Typography>
      }
      headerButtons={<div />}
    >
      {addrs?.map((addr, i) => (
        <div key={addr.invoice_address_id}>
          <InvoiceAddressBox addr={addr} index={i} />
        </div>
      ))}

      <Button variant="outlined" onClick={handleCreateEmptyAddr}>
        เพิ่มที่อยู่
      </Button>
    </Show>
  );
};
