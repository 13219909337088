import { Button, Card } from "@pankod/refine-mui";

interface Props {
  content_url?: string;
  setIsCertificate: () => any;
}

const CertificatePreview = ({ content_url, setIsCertificate }: Props) => {
  return (
    <Card>
      <div className="w-[1052px] h-[744px] overflow-hidden m-4">
        <div
          className="w-[3508px] h-[2480px]"
          style={{ scale: "0.3", transformOrigin: "top left" }}
        >
          <div className="relative flex justify-center">
            <div className="w-[3508px] h-[692px] absolute">
              <div className="flex justify-center mt-[1460px]">
                <img alt="content" src={content_url} />
              </div>
            </div>
            <img
              alt="certificate"
              src="/image/certificate.png"
              className="w-[3508px]"
            />
          </div>
        </div>
      </div>
      <div className="m-4">
        <Button variant="contained" onClick={setIsCertificate}>
          ย้อนกลับ
        </Button>
      </div>
    </Card>
  );
};

export default CertificatePreview;
