import { TextField } from "@mui/material";
import React from "react";
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  FieldErrors,
} from "react-hook-form";
import { MdDelete } from "react-icons/md";
import { FormDataCreate } from "./create";
import { IconButton } from "@pankod/refine-mui";

type BaseDivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface FormCardProps extends BaseDivProps {
  nestIndex: number;
  control: Control<FormDataCreate>;
  register: UseFormRegister<FormDataCreate>;
  emailContain: string;
  setValue: UseFormSetValue<FormDataCreate>;
  removeEmail: (arg: number) => void;
  errors: FieldErrors<FormDataCreate>;
  disabled?: boolean;
}

const FormEmailContainCard = React.forwardRef<HTMLDivElement, FormCardProps>(
  (
    {
      nestIndex,
      control,
      register,
      emailContain,
      setValue,
      removeEmail,
      className,
      errors,
      disabled,
      ...rest
    },
    ref
  ) => {
    return (
      <div
        className={`flex flex-col items-center w-full border p-4 gap-4 mb-3 `}
        ref={ref}
        {...rest}
      >
        <div className="w-full flex gap-2">
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            inputProps={{
              ...register(
                `course_discount.email_contain.${nestIndex}` as const,
                {
                  setValueAs: (value) => value?.trim() ?? "",
                }
              ),
            }}
            error={!!errors?.course_discount?.email_contain?.[nestIndex]}
            helperText={
              errors?.course_discount?.email_contain?.[nestIndex]
                ? "This field is required"
                : ""
            }
            label="อีเมลที่ต้องการ (เช่น @gmail.com)"
            variant="filled"
            name="course_discount.email_contain"
          />
        </div>

        {!disabled && (
          <>
            <hr className=" w-full" />
            <div className="flex w-full justify-end">
              <IconButton
                className="p-2 self-end"
                onClick={() => removeEmail(nestIndex)}
              >
                <MdDelete className="text-lg" size={24} />
              </IconButton>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default FormEmailContainCard;
