import { AuthProvider } from "@pankod/refine-core";
import { axiosInstance } from "@pankod/refine-simple-rest";
import { REST_PUBLIC_URI } from "environment";
import { formatError } from "utils/request";

const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    try {
      const response = await axiosInstance.post(
        `${REST_PUBLIC_URI}/api/v1/admin/auth/login`,
        {
          email,
          password,
        }
      );

      if (response.status === 200) {
        localStorage.setItem("auth", JSON.stringify(response.data));
        return Promise.resolve("/");
      }

      return Promise.reject("/");
    } catch (err) {
      throw formatError(err);
    }
  },
  checkAuth: async () => {
    try {
      const user = localStorage.getItem("auth");
      try {
        const response = await axiosInstance.get(
          `${REST_PUBLIC_URI}/api/v1/admin/users/me`
        );
        if (user && response.status === 200) {
          return Promise.resolve("/");
        }
      } catch (error) {
        return Promise.reject("/");
      }
      return Promise.reject("/");
    } catch (err) {
      throw formatError(err);
    }
  },
  logout: async () => {
    const response = await axiosInstance.post(
      `${REST_PUBLIC_URI}/api/v1/admin/auth/logout`
    );
    if (response.status === 200) {
      localStorage.removeItem("auth");
      return Promise.resolve("/");
    }

    return Promise.reject("/");
  },
  getPermissions: function (params?: any): Promise<any> {
    throw new Error("Function not implemented.");
  },
  checkError: function (error: any): Promise<void> {
    if (error.statusCode === 401) {
      localStorage.removeItem("auth");
      return Promise.reject("/");
    }
    return Promise.resolve();
  },
};

export default authProvider;
