import { useCallback, useState } from 'react'

const useModalState = () => {
  const [isShow, setIsShow] = useState(false)

  const onOpen = useCallback(() => {
    setIsShow(true)
  }, [])
  const onClose = useCallback(() => {
    setIsShow(false)
  }, [])
  const onToggle = useCallback(() => {
    setIsShow((prev) => !prev)
  }, [])

  return {
    isShow,
    setIsShow,
    onOpen,
    onClose,
    onToggle,
  }
}

export default useModalState
