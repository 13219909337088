import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  EditButton,
  List,
  ShowButton,
  CreateButton,
} from "@pankod/refine-mui";
import { REST_PUBLIC_URI } from "environment";

export const UserList = () => {
  const { dataGridProps } = useDataGrid({
    queryOptions: {
      retry: 1,
    },
  });

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "actions",
        headerName: "Action",
        renderCell: function render({ row }) {
          return (
            <>
              {row.profile_id && (
                <React.Fragment>
                  <ShowButton hideText recordItemId={row.profile_id} />
                  <EditButton hideText recordItemId={row.profile_id} />
                </React.Fragment>
              )}
              {!row.profile_id && (
                <React.Fragment>
                  <CreateButton
                    variant="outlined"
                    // id={row.user_id}
                    // resourceNameOrRouteName="profiles"
                    hideText
                    onClick={() =>
                      (window.location.href = `/member/profiles/create/${row.user_id}`)
                    }
                    href={`/member/profiles/create/${row.user_id}`}
                  />
                </React.Fragment>
              )}
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
        sortable: false,
      },
      {
        field: "No.",
        headerName: "No.",
        minWidth: 80,
        type: "number",
        headerAlign: "left",
        align: "left",
        renderCell: (index: any) =>
          index.api.getRowIndex(index.row.user_id) + 1,
      },
      {
        field: "user_id",
        headerName: "User ID",
        minWidth: 200,
        type: "number",
        valueFormatter: ({ value }) =>
          value.toLocaleString("en-US", { useGrouping: false }),
        headerAlign: "left",
        align: "left",
      },
      {
        field: "name",
        headerName: "Account Name",
        minWidth: 200,
        type: "text",
        headerAlign: "left",
        align: "left",
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        getRowId={(row) => row.user_id}
        columns={columns}
        autoHeight
        disableColumnFilter={true}
      />
    </List>
  );
};
